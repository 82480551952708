import React from 'react';
import { Col } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import './style.scss';
import { FormattedHTMLMessage } from 'react-intl';
import StepCard from 'components/StepCard';
import { Creators as StepsCreators } from '../../services/redux/extendVignetteSteps/actions';
import { DELAY_SECONDS } from 'constants/index';

let timer;
const Success = props => {
  clearTimeout(timer);
  timer = setTimeout(() => {
    window.location = 'https://Amsterdam.nl/varen';
  }, 200000);
  return (
    <StepCard id={props.titleId}>
      <Col sm={24} lg={24} xs={24} md={12} className="mobile-wrapper">
        <div className="success-icon-wrapper">
          {props.disabled ? (
            <React.Fragment>
              <img
                src={require('../../assets/activate-success-icon.png')}
                alt="success"
              />
              <p>
                <FormattedHTMLMessage
                  values={{
                    variable: props.variable,
                  }}
                  id={props.id}
                />
              </p>
            </React.Fragment>
          ) : (
            <Link to="/">
              <img
                src={require('../../assets/activate-success-icon.png')}
                alt="success"
              />
              <p>
                <FormattedHTMLMessage
                  values={{
                    variable: props.variable,
                  }}
                  id={props.id}
                />
              </p>
            </Link>
          )}
        </div>
      </Col>
    </StepCard>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    updateRoute: route => {
      dispatch(StepsCreators.updateExtendVignetteRoute(route));
    },
  };
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Success);
