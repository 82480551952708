import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions(
  {
    buyVignetGetTarifChoicesRequest: ['data'],
    buyVignetGetTarifChoicesSuccess: ['responseData'],
    buyVignetGetTarifChoicesFailure: ['error'],

    // clear tarif choices
    buyVignetClearTarifChoices: [],

    buyVignetLoginRequest: ['data'],
    buyVignetLoginSuccess: ['responseData'],
    buyVignetLoginFailure: ['error'],

    buyVignetCreateAccountRequest: ['data'],
    buyVignetCreateAccountSuccess: ['responseData'],
    buyVignetCreateAccountFailure: ['error'],

    buyVignetGetOwnerRequest: [],
    buyVignetGetOwnerSuccess: ['responseData'],
    buyVignetGetOwnerFailure: ['error'],

    buyVignetUpdateOwnerRequest: ['data'],
    buyVignetUpdateOwnerSuccess: ['responseData'],
    buyVignetUpdateOwnerFailure: ['error'],

    buyVignetGetObjectTypesRequest: [],
    buyVignetGetObjectTypesSuccess: ['responseData'],
    buyVignetGetObjectTypesFailure: ['error'],

    buyVignetGetDriveTypesRequest: [],
    buyVignetGetDriveTypesSuccess: ['responseData'],
    buyVignetGetDriveTypesFailure: ['error'],

    buyVignetCreateDayRequest: ['data'],
    buyVignetCreateDaySuccess: ['responseData'],
    buyVignetCreateDayFailure: ['error'],

    buyVignetGetBankListsRequest: ['data'],
    buyVignetGetBankListsSuccess: ['responseData'],
    buyVignetGetBankListsFailure: ['error'],

    buyVignetMakeDayPaymentRequest: ['issuer'],
    buyVignetMakeDayPaymentSuccess: ['responseData'],
    buyVignetMakeDayPaymentFailure: ['error'],

    buyVignetGetOverviewRequest: [],
    buyVignetGetOverviewSuccess: ['responseData'],
    buyVignetGetOverviewFailure: ['error'],

    buyVignetUploadDocumentsRequest: ['data'],
    buyVignetUploadDocumentsSuccess: ['responseData'],
    buyVignetUploadDocumentsFailure: ['error'],

    buyVignetHasMandateRequest: [],
    buyVignetHasMandateSuccess: ['responseData'],
    buyVignetHasMandateFailure: ['error'],

    buyVignetCreateMandateRequest: ['issuer'],
    buyVignetCreateMandateSuccess: ['responseData'],
    buyVignetCreateMandateFailure: ['error'],

    buyVignetGetInstallmentsRequest: [],
    buyVignetGetInstallmentsSuccess: ['responseData'],
    buyVignetGetInstallmentsFailure: ['error'],

    buyVignetGetVignetCostRequest: [],
    buyVignetGetVignetCostSuccess: ['responseData'],
    buyVignetGetVignetCostFailure: ['error'],

    buyVignetCreateDirectRequest: ['issuer'],
    buyVignetCreateDirectSuccess: ['responseData'],
    buyVignetCreateDirectFailure: ['error'],

    buyVignetCreateInstallmentRequest: [],
    buyVignetCreateInstallmentSuccess: ['responseData'],
    buyVignetCreateInstallmentFailure: ['error'],

    buyVignetCheckDayRequest: ['data'],
    buyVignetCheckDaySuccess: ['responseData'],
    buyVignetCheckDayFailure: ['error'],

    buyVignetConfirmationRequest: [],
    buyVignetConfirmationSuccess: ['responseData'],
    buyVignetConfirmationFailure: ['error'],

    buyVignetForgotPasswordRequest: ['data'],
    buyVignetForgotPasswordSuccess: ['responseData'],
    buyVignetForgotPasswordFailure: ['error'],

    buyVignetResetPasswordRequest: ['data'],
    buyVignetResetPasswordSuccess: ['responseData'],
    buyVignetResetPasswordFailure: ['error'],
  },
  {},
);
