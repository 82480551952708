import React from 'react';
import { BUY_VIGNNETTE_ROUTE } from 'constants/index';
import StepCard from 'components/StepCard';
import Title from 'components/Title';
import { Form, Alert, Row, Col } from 'antd';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import './styles.scss';
import { history } from 'util/helpers/history';

class BuyPaymentInstruction extends React.Component {
  state = {
    visible: true,
  };
  render() {
    history.location.state = {};
    setTimeout(() => {
      this.setState({ visible: false });
    }, 10000);
    return (
      <StepCard
        onPrevious={() =>
          this.props.updateRoute(BUY_VIGNNETTE_ROUTE.OVERVIEW_ROUTE)
        }
        onNext={() => this.props.checkMandate()}
        current={5}
        steps={7}
        id="buy.vignete.instruction.title"
        className="buy-payment-instruction"
        isNextLoading={this.props.isCheckingMandate}
      >
        <Row>
          <div>
            <p className="title">
              <FormattedMessage id="buy.vignet.payment.instruction.title" />
            </p>
          </div>
          <Col span={24} xs={24} lg={24} md={24} sm={24}>
            <div className="payment-type-wrapper">
              <p className="payment-info">
                <FormattedMessage id="buy.vignet.payment.instruction.description" />
              </p>
            </div>
            <div className="payment-type-wrapper">
              <p className="payment-title">
                <FormattedMessage id="buy.vignet.payment.instruction.date" />
              </p>
              <p className="payment-info">
                <FormattedMessage id="buy.vignet.payment.instruction.date.content" />
              </p>
              <p className="payment-info">
                <FormattedHTMLMessage id="buy.vignet.payment.instruction.date.content.more" />
              </p>
            </div>
            <div className="payment-type-wrapper">
              <p className="payment-title">
                <FormattedMessage id="buy.vignet.payment.instruction.issue" />
              </p>
              <p className="payment-info">
                <FormattedMessage id="buy.vignet.payment.instruction.issue.content" />
              </p>
            </div>
            <div className="payment-type-wrapper">
              {this.props.mandateResponseMsg && this.state.visible ? (
                <Alert
                  type="error"
                  message={
                    <FormattedMessage id={this.props.mandateResponseMsg} />
                  }
                />
              ) : null}
            </div>
          </Col>
        </Row>
      </StepCard>
    );
  }
}

export default BuyPaymentInstruction;
