import React from 'react';
import { history } from 'util/helpers/history';
import MandateBankForm from 'containers/ExtendVignetteSteps/Steps/MandateBankLists/components';

const AnnualMandate = props => {
  return (
    <MandateBankForm
      id="buy.vignete.instruction.title"
      getBankLists={() => props.getBankLists({ list_type: 'mandate' })}
      banks={props.banks}
      isFetchingBanks={props.isFetchingBanks}
      isFetchingMandate={props.isCreatingMandate}
      postMandate={data => props.createMandate(data)}
      onPrevious={() => history.goBack()}
    />
  );
};

export default AnnualMandate;
