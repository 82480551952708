import React from 'react';
import {
  Row,
  Col,
  Input,
  Modal,
  Checkbox,
  Button,
  Tooltip,
  Icon,
  Form,
  Alert,
} from 'antd';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import './styles.scss';
import { EXTEND_VIGNETTE_ROUTE } from 'constants/index';
import StepCard from 'components/StepCard';
import { formatAmountToNL } from 'util/helpers/normalizer';

const ENVIRONMENT_BOAT = 'Milieu';
const SUBSCRIPTION_BOAT = 'Abonnement';
let timer;

class ExtendRate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      checkedConfirmation: false,
      visible: true,
    };
  }

  static propTypes = {
    goBack: PropTypes.func.isRequired,
    goNext: PropTypes.func.isRequired,
    data: PropTypes.shape({
      object_lenght: PropTypes.number.isRequired,
      object_width: PropTypes.number.isRequired,
      object_surface: PropTypes.string,
      vignet_type_id: PropTypes.string,
      vignet_cost: PropTypes.number.isRequired,
      vignet_type_name: PropTypes.string,
    }).isRequired,
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ visible: true });
        if (!values.object_location) {
          this.props.updateRoute(EXTEND_VIGNETTE_ROUTE.CHOICE_ROUTE);
        }
        this.props.updateObjectLocation(values.object_location);
      }
    });
  };

  closeModal = e => {
    this.setState({ showModal: false });
  };

  checkCheckBox = (rule, value, callback) => {
    if (!value) {
      callback(<FormattedMessage id="help.confirm.this" />);
    } else {
      callback();
    }
  };

  getBoatTypeInstructionMsg = () => {
    const { vignet_type_name } = this.props.data;
    if (vignet_type_name === ENVIRONMENT_BOAT) {
      return 'extend,rate.modal.enivronment.confirmation';
    }

    return 'extend.rate.modal.subscription.confirmation';
  };

  renderServerErrorMsg = () => {
    const { error } = this.props;
    if (error) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        this.setState({ visible: false });
      }, 5000);
      if (this.state.visible) {
        return (
          <div className="error-msg">
            <Alert description={error} type="error" />
          </div>
        );
      }
    }
  };

  renderModal() {
    const { vignet_type_name } = this.props.data;
    const { getFieldDecorator } = this.props.form;
    return (
      <Modal
        visible={this.state.showModal}
        onCancel={this.closeModal}
        footer={null}
        maskStyle={{
          backgroundColor: '#DDE4EB',
          opacity: 0.6,
        }}
        width={840}
      >
        <Form onSubmit={this.handleSubmit}>
          <div className="modal-wrapper">
            <p className="modal-title">
              <FormattedMessage id="confirmation" />
            </p>
            <p className="modal-instruction">
              <FormattedMessage id="extend.rate.modal.instruction" />
            </p>
            <p className="modal-instruction">
              <FormattedMessage id="extend.rate.modal.more.instruction" />
            </p>
            <div className="checkbox-container">
              <div className="checkbox-wrapper">
                <Form.Item className="no-spacing">
                  {getFieldDecorator('checkbox', {
                    valuePropName: 'checked',
                    rules: [{ validator: this.checkCheckBox }],
                  })(
                    <Checkbox>
                      <FormattedMessage id="extend.rate.modal.confirm" />
                    </Checkbox>,
                  )}
                </Form.Item>
              </div>
              {vignet_type_name &&
              [ENVIRONMENT_BOAT, SUBSCRIPTION_BOAT].includes(
                vignet_type_name,
              ) ? (
                <React.Fragment>
                  <div className="checkbox-wrapper">
                    <Form.Item className="no-spacing">
                      {getFieldDecorator('secondcheckbox', {
                        valuePropName: 'checked',
                        rules: [{ validator: this.checkCheckBox }],
                      })(
                        <Checkbox>
                          <FormattedMessage
                            id={this.getBoatTypeInstructionMsg()}
                          />
                          {vignet_type_name == SUBSCRIPTION_BOAT ? (
                            <Col push={1} className="mt-10">
                              <i className="note-text">
                                <FormattedMessage id="extend.abonnment.vignette.message" />
                              </i>
                            </Col>
                          ) : null}
                        </Checkbox>,
                      )}
                    </Form.Item>
                  </div>
                  {vignet_type_name === SUBSCRIPTION_BOAT ? (
                    <div className="mt-20">
                      <label>
                        <FormattedMessage id="extend.step.rate.marina.name" />
                      </label>
                      <Form.Item>
                        {getFieldDecorator('object_location', {
                          rules: [
                            {
                              required: true,
                              message: <FormattedMessage id="required.error" />,
                            },
                          ],
                        })(<Input />)}
                      </Form.Item>
                    </div>
                  ) : null}
                </React.Fragment>
              ) : null}
            </div>
            {this.renderServerErrorMsg()}
            <div className="confirm-btn">
              <Button
                type="primary"
                htmlType="submit"
                disabled={this.props.isUpdating}
                loading={this.props.isUpdating}
              >
                <FormattedMessage id="CONFIRM" />
              </Button>
            </div>
          </div>
        </Form>
      </Modal>
    );
  }

  renderTable = () => {
    return (
      <table border={1} className="vignette-rate">
        <tr>
          <th className="vignette-rate-header">
            Oppervlakte vaartuig (in m<sup>2</sup>)
          </th>
          <th className="vignette-rate-header">
            <Tooltip
              title={
                <FormattedMessage id="extend.step.vignette.rate.second.hover.message" />
              }
            >
              <Icon type="info-circle" className="info-icon-wrapper" />
              &nbsp; Vignet Regulier
            </Tooltip>
          </th>
          <th className="vignette-rate-header">
            <Tooltip
              title={
                <FormattedMessage id="extend.step.vignette.rate.third.hover.message" />
              }
            >
              <Icon type="info-circle" className="info-icon-wrapper" />
              &nbsp; Vignet Milieu
            </Tooltip>
          </th>
          <th className="vignette-rate-header">
            <Tooltip
              title={
                <FormattedMessage id="extend.step.vignette.rate.fourth.hover.message" />
              }
            >
              <Icon type="info-circle" className="info-icon-wrapper" />
              &nbsp; Vignet Abonnement
            </Tooltip>
          </th>
        </tr>
        <tr>
          <td>0 tot 3,5</td>
          <td>&#8364; 95</td>
          <td>&#8364; 28,50</td>
          <td>&#8364; 47,50</td>
        </tr>
        <tr>
          <td>vanaf 3,5 tot 6,0</td>
          <td>&#8364; 74</td>
          <td>&#8364; 22</td>
          <td>&#8364; 37</td>
        </tr>
        <tr>
          <td>vanaf 6,0 tot 9,0</td>
          <td>&#8364; 66</td>
          <td>&#8364; 19,50</td>
          <td>&#8364; 33</td>
        </tr>
        <tr>
          <td>vanaf 9,0 tot 12,5</td>
          <td>&#8364; 61</td>
          <td>&#8364; 18</td>
          <td>&#8364; 30,50</td>
        </tr>
        <tr>
          <td>vanaf 12,5 tot 16,5</td>
          <td>&#8364; 57</td>
          <td>&#8364; 17</td>
          <td>&#8364; 28,50</td>
        </tr>
        <tr>
          <td>vanaf 16,5 en groter</td>
          <td>&#8364; 55</td>
          <td>&#8364; 16,50</td>
          <td>&#8364; 27,50</td>
        </tr>
      </table>
    );
  };

  render() {
    const { data } = this.props;
    return (
      <StepCard
        onPrevious={() =>
          this.props.updateRoute(EXTEND_VIGNETTE_ROUTE.VERIFICATION_STEP)
        }
        onNext={() => this.setState({ showModal: true })}
        current={1}
        steps={7}
        id="form.title"
        className="rate-step"
        contactDetails="extend.rate.description"
      >
        <Row className="wrapper">
          <div>
            <p className="title">
              <FormattedMessage id="extend.rate.title" />
            </p>
          </div>
          {this.renderModal()}
          <Col span={24} xs={24} lg={12} md={24} sm={24}>
            <div className="info-wrapper">
              <p>
                <FormattedMessage id="extend.rate.instruction" />
              </p>
              <p>
                <strong>
                  <FormattedMessage id="extend.rate.instruction.more" />
                </strong>
              </p>
              <div className="img-wrapper">
                {/* <img
                  alt="rate"
                  src={require('../../../../../assets/price-table.svg')}
                /> */}
                {this.renderTable()}
              </div>
              {/* <p>
                <FormattedMessage id="extend.rate.description" />
                <br />
                Tel: 0800123456
                <br />
                contact@digitaalvignet.nl
              </p> */}
            </div>
          </Col>
          <Col span={12} xs={24} lg={2} md={4} sm={24}></Col>
          <Col span={24} xs={24} lg={10} md={24} sm={24}>
            <div className="input-wrapper">
              <label>
                <FormattedMessage id="extend.rate.type" />*
              </label>
              <Input disabled value={data && data.vignet_type_name} />
            </div>
            <Row>
              <Col span={24} xs={24} lg={11} md={24} sm={24}>
                <div className="input-wrapper">
                  <label>
                    <FormattedMessage id="extend.rate.length" />*
                  </label>
                  <Input
                    disabled
                    suffix="m"
                    value={data && formatAmountToNL(data.object_lenght)}
                  />
                </div>
              </Col>
              <Col span={24} xs={24} lg={13} md={24} sm={24}>
                <div className="input-wrapper">
                  <label>
                    <FormattedMessage id="extend.rate.width" />*
                  </label>
                  <Input
                    disabled
                    suffix="m"
                    value={data && formatAmountToNL(data.object_width)}
                  />
                </div>
              </Col>
            </Row>
            <div className="input-wrapper">
              <label>
                <FormattedMessage id="extend.rate.surface" />*
              </label>
              <Input
                disabled
                suffix={
                  <span>
                    m<sup>2</sup>
                  </span>
                }
                value={data && formatAmountToNL(data.object_surface)}
              />
            </div>
            <div className="input-wrapper">
              <label>
                <FormattedMessage id="extend.rate.cost" />*
              </label>
              <Input
                disabled
                suffix="&#8364;"
                value={data && formatAmountToNL(data.vignet_cost)}
              />
            </div>
          </Col>
        </Row>
      </StepCard>
    );
  }
}

const RateForm = Form.create({ name: 'RateForm' })(ExtendRate);

export default RateForm;
