import { connect } from 'react-redux';

import { Creators as StepCreators } from '../../../../services/redux/extendVignetteSteps/actions';
import Instruction, { InitializeDayExtension } from './components';
import { BUY_VIGNNETTE_ROUTE } from 'constants/index';

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    goToVignetteInfo: () => {
      dispatch(
        StepCreators.updateBuyVignetteRoute(
          BUY_VIGNNETTE_ROUTE.TARIF_CHOICES_ROUTE,
        ),
      );
    },
    goToPersonalInfo: () => {
      dispatch(
        StepCreators.updateBuyVignetteRoute(
          BUY_VIGNNETTE_ROUTE.ONE_DAY_VIGNET.PERSONAL_INFO_ROUTE,
        ),
      );
    },
  };
};

export const InitializeDay = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InitializeDayExtension);
export default connect(mapStateToProps, mapDispatchToProps)(Instruction);
