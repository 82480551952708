// global app state for things like internationalization, etc...
import { createReducer } from 'reduxsauce';
import appReducer from '../../../rootReducer';

import { locales } from 'lang';
import Types from './actionTypes';

// TODO: be nl
const appDefaultLanguage = 'nl';
const userCachedPreference = sessionStorage.getItem('bhg-language');
const userLanguage = '';
// const userLanguage = navigator.language;

export const INITIAL_STATE = {
  language: locales[userCachedPreference || userLanguage]
    ? userCachedPreference || userLanguage
    : appDefaultLanguage,
};

export const languageChange = (state = INITIAL_STATE, { language }) => {
  if (locales[language]) {
    return { ...state, language };
  }

  return state;
};

export const logOut = (state, action) => {
  state = undefined;
  return appReducer(state, action);
};

export const HANDLERS = {
  [Types.LANGUAGE_CHANGE]: languageChange,
  [Types.LOGOUT]: logOut,
};

export default createReducer(INITIAL_STATE, HANDLERS);
