import React from 'react';
import { Row, Col, Card, Button, Icon } from 'antd';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import Title from 'components/Title';
import './style.scss';

const Instruction = ({
  header,
  content,
  contact,
  subheader,
  iconURL,
  iconStyle,
  messages,
  description,
  onStart,
  className = '',
}) => {
  return (
    <Row
      type="flex"
      justify="center"
      align="middle"
      style={{ minHeight: '90vh' }}
      className={className}
    >
      <Col xs={24} md={22} sm={24} sm={24} lg={16} className="mobile-wrapper">
        <Title id={header} />

        <Card align="center">
          <div className="sub-title-wrapper">
            <p className="bold-sub-title">
              {content && (
                <p>
                  <FormattedHTMLMessage id={content} />
                </p>
              )}
              <FormattedMessage
                id={subheader}
                values={{
                  subtext: text => (
                    <span className="light-sub-title"> {text} </span>
                  ),
                }}
              />
            </p>
            {false && className === 'activation-steps' && (
              <Button
                className="instructions-subheading-button"
                type="primary"
                onClick={onStart}
              >
                <FormattedMessage id={'INSTUCTIES'} />{' '}
              </Button>
            )}{' '}
          </div>
          <div className="instruction-lists-wrapper">
            <Row>
              <Col sm={24} md={24} lg={8} xs={24} className="hide-on-modal">
                <div
                  className={`hide-on-modal ${!description ? 'padded' : ''}`}
                >
                  <img
                    className="instructions-icon"
                    src={iconURL}
                    alt="instruction description"
                    style={iconStyle}
                  />
                </div>
                {description && (
                  <div className="hide-on-modal">
                    <p>
                      <FormattedMessage id={description.title} />
                    </p>
                    <ul className="extra">
                      {description.bullets.map(id => {
                        return (
                          <li key={id}>
                            <FormattedMessage id={id} />
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </Col>
              <Col sm={24} md={24} lg={16} xs={24} className="instruction-col">
                {messages.map((id, index) => (
                  <div className="instruction-list" key={index}>
                    <div className="badge-icon">
                      <p className="badge-text">{index + 1}</p>
                    </div>
                    <span className="list-text">
                      <FormattedMessage id={id} />
                    </span>
                  </div>
                ))}
              </Col>
            </Row>
          </div>
          <Button className="start-btn" type="primary" onClick={onStart}>
            <FormattedMessage id={'START'} />{' '}
            <Icon className="btn-icon" type="arrow-right" />
          </Button>
          {contact ? (
            <div className="contact-info">
              <p>
                <FormattedHTMLMessage id={contact} />
              </p>
            </div>
          ) : null}
        </Card>
      </Col>
    </Row>
  );
};

Instruction.defaultProps = {
  header: 'header',
  subheader: 'subheader',
  messages: [],
};

Instruction.propTypes = {
  header: PropTypes.string.isRequired,
  subheader: PropTypes.string.isRequired,
  messages: PropTypes.arrayOf(PropTypes.string).isRequired,
  onStart: PropTypes.func.isRequired,
  iconURL: PropTypes.string.isRequired,
  description: PropTypes.shape({
    title: PropTypes.string,
    bullets: PropTypes.array,
  }),
};

export default Instruction;
