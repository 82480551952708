import moment from 'moment';

export function normalizeData(data) {
  const newData = [];
  /* eslint-disable array-callback-return */
  Object.keys(data).map(key => {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      newData.push({ ...data[key], id: key });
    }
  });
  return newData;
}

export const logOut = () => {
  localStorage.clear();
  sessionStorage.clear();
  window.location = '/';
  return;
};

export const formatAmountToNL = amount =>
  new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(amount);

export const formatOverviewData = customer => {
  const customerInfo = [
    {
      id: 'lastname',
      value: customer.last_name,
    },
    {
      id: 'insertion',
      value: customer.prefix,
    },
    {
      id: 'intial',
      value: customer.initials,
    },
    {
      id: 'address',
      value: customer.address,
    },
    {
      id: 'house.number',
      value: customer.number,
    },
    {
      id: 'addition',
      value: customer.addition,
    },
    {
      id: 'postal.code',
      value: customer.postcode,
    },
    {
      id: 'place',
      value: customer.city,
    },
    {
      id: 'telephone.number',
      value: customer.phone,
    },

    {
      id: 'mobile',
      value: customer.mobile_phone,
    },

    {
      id: 'email',
      value: customer.email,
    },
    {
      id: 'Burgerservicenummer',
      value: customer.bsn,
    },
  ];

  const vignetData = [
    {
      id: 'vignet_type',
      value: customer.vignet_type,
    },
    {
      id: 'buy.vignet.marina.place',
      value: customer.object_marina === '1' ? 'Ja' : 'Nee',
    },
    {
      id: 'rate',
      value: `€ ${formatAmountToNL(customer.cost)}`,
    },
    {
      id: 'length.of.boat',
      value: `${formatAmountToNL(customer.object_length)} m`,
    },
    {
      id: 'width.of.boat',
      value: `${formatAmountToNL(customer.object_width)} m`,
    },
    {
      id: 'buy.vignet.tarif.boat.surface',
      value: `${formatAmountToNL(customer.surface)} m`,
    },
  ];

  sessionStorage.setItem('customer_info', JSON.stringify(customerInfo));
  sessionStorage.setItem('vignet_info', JSON.stringify(vignetData));
  return {
    vignetData,
    customerInfo,
  };
};

export const timeStampDifference = (timestamp1, timestamp2) => {
  return timestamp1 - timestamp2;
};
