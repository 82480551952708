import React from 'react';
import { Modal, Row, Col, Button } from 'antd';
import { FormattedMessage } from 'react-intl';

export const ConfirmationModal = props => {
  const { visible, onConfirm, onCancel } = props;
  return (
    <Modal
      className="forgot-pin"
      centered
      visible={visible}
      footer={null}
      onCancel={onCancel}
    >
      <Row type="flex" justify="center">
        <h2>
          <FormattedMessage id="confirmation" />
        </h2>
        <br />
      </Row>

      <Row type="flex" justify="center">
        <Col xs={24} lg={20} align="center">
          <p>
            <FormattedMessage
              id="activate.steps.four.confirm.message"
              values={{ break: <br /> }}
            />
          </p>
        </Col>
      </Row>

      <Row type="flex" justify="center">
        <Button type="primary" onClick={onConfirm}>
          <FormattedMessage id="CONFIRM" />
        </Button>
      </Row>
    </Modal>
  );
};

export default ConfirmationModal;
