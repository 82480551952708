import React from 'react';
import { Row, Col, Alert } from 'antd';
import { FormattedMessage } from 'react-intl';

import { EXTEND_VIGNETTE_ROUTE } from 'constants/index';
import './styles.scss';
import StepCard from 'components/StepCard';
import { history } from 'util/helpers/history';

class PaymentInstruction extends React.Component {
  state = {
    visible: true,
  };
  render() {
    history.location.state = {};
    setTimeout(() => {
      this.setState({ visible: false });
    }, 5000);
    return (
      <StepCard
        id="extend.step.personal.title"
        current={5}
        steps={7}
        onPrevious={() =>
          this.props.updateRoute(EXTEND_VIGNETTE_ROUTE.ACCOUNT_STEP)
        }
        onNext={() =>
          this.props.updateRoute(EXTEND_VIGNETTE_ROUTE.PAYMENT_MANDATE)
        }
        className="payment-instruction-steps"
      >
        <Row>
          <div>
            <p className="title">
              <FormattedMessage id="extend.step.payment.terms.title" />
            </p>
          </div>
          <Col span={24} xs={24} lg={24} md={24} sm={24}>
            <div className="payment-type-wrapper">
              <p className="payment-info">
                <FormattedMessage id="extend.step.payment.terms.one" />
              </p>
            </div>
            <div className="payment-type-wrapper">
              <p className="payment-title">
                <FormattedMessage id="extend.step.payment.terms.header.one.title" />
              </p>
              <p className="payment-info">
                <FormattedMessage id="extend.step.payment.terms.header.one.content" />
              </p>
            </div>
            <div className="payment-type-wrapper">
              <p className="payment-title">
                <FormattedMessage id="extend.step.payment.terms.header.two.title" />
              </p>
              <p className="payment-info">
                <FormattedMessage id="extend.step.payment.terms.header.two.content" />
              </p>
            </div>
            <div className="payment-type-wrapper">
              <p className="payment-info">
                <FormattedMessage id="extend.payment.instruction.more.context" />
              </p>
              {this.props.mandateResponseMsg && this.state.visible ? (
                <Alert
                  type="error"
                  message={
                    <FormattedMessage id={this.props.mandateResponseMsg} />
                  }
                />
              ) : null}
            </div>
          </Col>
        </Row>
      </StepCard>
    );
  }
}

export default PaymentInstruction;
