import React from 'react';
import CustomerInfoForm from 'containers/ExtendVignetteSteps/Steps/CustomerInfo/components';
import { history } from 'util/helpers/history';

const PersonalInfoForm = props => {
  return (
    <CustomerInfoForm
      current={0}
      steps={4}
      getOwner={() => console.log()}
      customer={
        JSON.parse(sessionStorage.getItem('one_day_personal_form')) || {}
      }
      onPrevious={() => history.goBack()}
      createDetails={data => props.checkDay(data)}
      isUpdating={props.checkingDay}
      updateError={props.checkDayError}
      title="buy.vignet.instruction.one.day.title"
      formTitle="buy.vignet.personal.info.form.title"
      newDayVignet={true}
      formIdentifier="one_day_personal_form"
    />
  );
};

export default PersonalInfoForm;
