import React from 'react';
import StepCard from 'components/StepCard';
import Title from 'components/Title';
import { Form, Upload, Row, Col, Alert } from 'antd';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import './styles.scss';
import { history } from 'util/helpers/history';

const { Dragger } = Upload;
let timer;

class AnnualUploadFiles extends React.Component {
  state = {};
  handleSubmit = e => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const data = new FormData();
        const documents = JSON.parse(sessionStorage.getItem('tarifChoices'));
        documents.documents.map(d => {
          values[d].fileList.map(files => {
            data.append('documents[]', files.originFileObj);
          });
        });
        this.props.uploadDocuments(data);
      }
    });
  };

  renderServerErrorMsg = () => {
    const { error } = this.props;
    if (error) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        this.setState({ visible: false });
      }, 5000);
      if (this.state.visible) {
        return (
          <div className="error-msg">
            <Alert description={error} type="error" />
          </div>
        );
      }
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const documents = JSON.parse(sessionStorage.getItem('tarifChoices'));
    return (
      <Form onSubmit={this.handleSubmit}>
        <StepCard
          onPrevious={() => history.goBack()}
          onNext={this.handleSubmit}
          current={2}
          steps={7}
          id="buy.vignete.instruction.title"
          className="buy-vignet-tarif-choices"
          isNextLoading={this.props.isLoading}
        >
          <Title id="upload.files" />
          <p>
            <FormattedHTMLMessage id="upload.files.instruction" />
          </p>
          <Row gutter={24} className="upload-wrapper">
            <Col
              span={24}
              xs={24}
              lg={24}
              md={24}
              sm={24}
              className="form-wrapper"
            >
              {documents.documents.map(data => {
                return (
                  <React.Fragment>
                    <h4>{data}</h4>
                    <Form.Item>
                      {getFieldDecorator(data, {
                        rules: [
                          {
                            required: true,
                            message: <FormattedMessage id="fileRequired" />,
                          },
                        ],
                      })(
                        <Dragger
                          beforeUpload={() => false}
                          accept={'.png, .jpg, .pdf, .doc, .docx'}
                        >
                          <p className="ant-upload-text">
                            <FormattedMessage id="upload.files.message" />
                            <a>
                              <FormattedMessage id="upload.files.select.files" />
                            </a>
                          </p>
                        </Dragger>,
                      )}
                      {data === 'Ligplaatsbewijs' && (
                        <p>
                          <FormattedHTMLMessage id="buy.vignet.upload.documents.lig.info" />
                        </p>
                      )}
                    </Form.Item>
                  </React.Fragment>
                );
              })}
              {this.renderServerErrorMsg()}
            </Col>
          </Row>
        </StepCard>
      </Form>
    );
  }
}

const AnnualUploadFilesForm = Form.create({ name: 'AnnualUploadFilesForm' })(
  AnnualUploadFiles,
);

export default AnnualUploadFilesForm;
