import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Row, Col, Button, Form, Spin } from 'antd';

import Dropzone from 'components/Dropzone';

export class PicturesForm extends React.Component {
  render() {
    const { isLoading } = this.props;

    if (isLoading) {
      return (
        <Row type="flex" justify="center">
          <Spin />
        </Row>
      );
    }

    const {
      distant_photo_url,
      detail_photo_url,
      isDistantError,
      isDetailError,
      distant,
      detail,
      handleFileInput,
      handleFilesDrop,
      openUploadDialog,
      handleUploadClick,
      isError,
      help,
    } = this.props;

    return (
      <Form>
        <input
          name="distant_photo"
          type="file"
          accept="image/jpeg,image/jpg,image/png"
          ref={distant}
          onChange={handleFileInput}
          hidden
        />
        <input
          name="detail_photo"
          type="file"
          accept="image/jpeg,image/jpg,image/png"
          ref={detail}
          onChange={handleFileInput}
          hidden
        />

        <Row>
          <Row type="flex" justify="center">
            <Col xs={24} lg={16} xl={16} xxl={17}>
              <Row type="flex" justify="center">
                <Col xs={24} lg={11} xl={13} xxl={9}>
                  <Dropzone
                    name="distant_photo"
                    onFilesDrop={handleFilesDrop}
                    onClick={openUploadDialog}
                  >
                    <img src={distant_photo_url} alt="distant_photo" />
                  </Dropzone>
                  {isDistantError && (
                    <Form.Item
                      help={
                        <FormattedMessage id="activate.errors.pick.picture" />
                      }
                      validateStatus="error"
                    ></Form.Item>
                  )}
                </Col>

                <Col lg={1} />

                <Col xs={24} lg={7} xl={9} xxl={7} align="left">
                  <h3>
                    <FormattedMessage id="activate.steps.four.distant.title" />
                  </h3>
                  <p>
                    <FormattedMessage id="activate.steps.four.distant.description" />
                  </p>

                  <Button
                    name="distant_photo"
                    className="step-complete"
                    type="primary"
                    onClick={handleUploadClick}
                  >
                    <FormattedMessage id="SELECT.PHOTO" />
                  </Button>
                </Col>
              </Row>
            </Col>

            <Col xs={24}>
              <br />
              <br />
            </Col>

            <Col xs={24} lg={16} xl={16} xxl={17}>
              <Row type="flex" justify="center">
                <Col xs={24} lg={11} xl={13} xxl={9}>
                  <Dropzone
                    name="detail_photo"
                    onFilesDrop={handleFilesDrop}
                    onClick={openUploadDialog}
                  >
                    <img src={detail_photo_url} alt="detail_photo" />
                  </Dropzone>
                  {isDetailError && (
                    <Form.Item
                      help={
                        <FormattedMessage id="activate.errors.pick.picture" />
                      }
                      validateStatus="error"
                    ></Form.Item>
                  )}
                </Col>

                <Col lg={1} />

                <Col xs={24} lg={7} xl={9} xxl={7} align="left">
                  <h3>
                    <FormattedMessage id="activate.steps.four.detail.title" />
                  </h3>
                  <p>
                    <FormattedMessage id="activate.steps.four.detail.description" />
                  </p>
                  <Button
                    className="step-complete"
                    type="primary"
                    name="detail_photo"
                    onClick={handleUploadClick}
                  >
                    <FormattedMessage id="SELECT.PHOTO" />
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Row>

        <br />
        {isError && <Form.Item help={help} validateStatus="error"></Form.Item>}
      </Form>
    );
  }
}

PicturesForm.propTypes = {
  // distant_photo_url: PropTypes.string.isRequired,
  // detail_photo_url: PropTypes.string.isRequired,
};

export default React.forwardRef((props, ref) => {
  const { distant, detail } = ref;

  return <PicturesForm {...props} distant={distant} detail={detail} />;
});
