import { connect } from 'react-redux';

import { Creators } from 'services/redux/buyVignette/actions';
import RegisterForm from './components';

const mapStateToProps = state => {
  return {
    isCreatingAccount: state.buyVignet.isCreatingAccount,
    accountError: state.buyVignet.accountError,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createAccount: data => {
      dispatch(Creators.buyVignetCreateAccountRequest(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);
