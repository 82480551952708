import { call, put, takeLeading } from 'redux-saga/effects';
import { Creators, Types } from '../../redux/auth/actions';
// import { Creators as UserCreators, Types as UserTypes } from '../../redux/user/actions';
import { login as loginApi } from '../../api/auth';

export function* login(actions) {
  try {
    const { username, password } = actions;
    const response = yield call(loginApi, { username, password });
    yield put(Creators.loginSuccess(response.data));
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.loginFailure(error_message));
  }
}

export function* watchLogin() {
  yield takeLeading(Types.LOGIN_REQUEST, login);
}
