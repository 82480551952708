import React from 'react';
import { Row, Col, Input, Form, Alert, DatePicker, Select } from 'antd';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';

import './styles.scss';
import { BUY_VIGNNETTE_ROUTE, DATE_FORMAT } from 'constants/index';
import StepCard from 'components/StepCard';
import { formatAmountToNL } from 'util/helpers/normalizer';

const { Option } = Select;

let timer;

class OneDayTarifChoice extends React.Component {
  state = {
    visible: true,
    timeout: null,
  };

  componentDidMount() {
    this.props.getObjectTypes();
    this.props.getDriveTypes();
  }

  handleSubmit = e => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ visible: true });
        const customer = JSON.parse(sessionStorage.getItem('customer'));
        delete customer.confirm_email;
        delete values.date;
        this.persist_user_info(customer, values);
        this.props.createDayRequest({ ...customer, ...values });
      }
    });
  };

  persist_user_info = (customer, values) => {
    const customerInfo = [
      {
        id: 'lastname',
        value: customer.last_name,
      },
      {
        id: 'insertion',
        value: customer.prefix,
      },
      {
        id: 'intial',
        value: customer.initials,
      },
      {
        id: 'address',
        value: customer.address,
      },
      {
        id: 'house.number',
        value: customer.number,
      },
      {
        id: 'addition',
        value: customer.addition,
      },
      {
        id: 'postal.code',
        value: customer.postcode,
      },
      {
        id: 'place',
        value: customer.city,
      },
      {
        id: 'telephone.number',
        value: customer.phone,
      },

      {
        id: 'mobile',
        value: customer.mobile_phone,
      },

      {
        id: 'email',
        value: customer.email,
      },
    ];

    const vignetData = [
      {
        id: 'vignet_type',
        value: 'Dag Vignet',
      },
      {
        id: 'extend.installmennt.date',
        value: moment().format(DATE_FORMAT),
      },
      {
        id: 'buy.vignet.boat.type',
        value: values.object_name,
      },
      {
        id: 'request.overview.drive',
        value: this.props.objectTypes.filter(
          data => data.id === values.object_type,
        )[0].name,
      },
      {
        id: 'buy.vignet.boat.name',
        value: this.props.driveTypes.filter(
          data => data.id === values.drive_type,
        )[0].name,
      },
      {
        id: 'request.overview.amount.toPay',
        value: `€ ${formatAmountToNL(30)}`,
      },
    ];
    sessionStorage.setItem('customer_info', JSON.stringify(customerInfo));
    sessionStorage.setItem('vignet_info', JSON.stringify(vignetData));
  };

  renderServerErrorMsg = () => {
    const { error } = this.props;
    if (error) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        this.setState({ visible: false });
      }, 5000);
      if (this.state.visible) {
        return (
          <div className="error-msg">
            <Alert description={error} type="error" />
          </div>
        );
      }
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <StepCard
          onPrevious={() =>
            this.props.updateRoute(
              BUY_VIGNNETTE_ROUTE.ONE_DAY_VIGNET.PERSONAL_INFO_ROUTE,
            )
          }
          onNext={this.handleSubmit}
          current={1}
          steps={4}
          id="buy.vignet.instruction.one.day.title"
          className="buy-one-day-vignet-tarif-choices"
          isNextLoading={this.props.isCreatingDay}
          isLoading={
            this.props.isGettingObjectTypes || this.props.isGettingDriveTypes
          }
        >
          <Row className="wrapper">
            <div>
              <p className="title">
                <FormattedMessage id="buy.vinget.instruction.one.day.item.2" />
              </p>
              <p className="subHeader">
                <FormattedHTMLMessage id="buy.one.day.vignet.date.restriction" />
              </p>
            </div>
            <Col span={24} xs={24} lg={6} md={24} sm={24} push={10}>
              <label>
                <FormattedMessage id="date" />
              </label>
              <Form.Item>
                {getFieldDecorator('date', {
                  initialValue: moment(),
                  rules: [
                    {
                      required: true,
                      message: <FormattedMessage id="required.error" />,
                    },
                  ],
                })(<DatePicker style={{ width: '100%' }} disabled />)}
              </Form.Item>
              <div>
                <label>
                  <FormattedMessage id="buy.vignet.boat.name" />
                </label>
                <Form.Item>
                  {getFieldDecorator('object_name', {
                    rules: [
                      {
                        required: true,
                        message: <FormattedMessage id="required.error" />,
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
                <label>
                  <FormattedMessage id="buy.vignet.boat.type" />*
                </label>
                <Form.Item>
                  {getFieldDecorator('object_type', {
                    rules: [
                      {
                        required: true,
                        message: <FormattedMessage id="required.error" />,
                      },
                    ],
                  })(
                    <Select>
                      {this.props.objectTypes.map(data => {
                        return <Option value={data.id}>{data.name}</Option>;
                      })}
                    </Select>,
                  )}
                </Form.Item>
                <label>
                  <FormattedMessage id="buy.vignet.boat.propulsion" />
                </label>
                <Form.Item>
                  {getFieldDecorator('drive_type', {
                    rules: [
                      {
                        required: true,
                        message: <FormattedMessage id="required.error" />,
                      },
                    ],
                  })(
                    <Select>
                      {this.props.driveTypes.map(data => {
                        return <Option value={data.id}>{data.name}</Option>;
                      })}
                    </Select>,
                  )}
                </Form.Item>
              </div>
              {this.renderServerErrorMsg()}
            </Col>
          </Row>
        </StepCard>
      </Form>
    );
  }
}
const OneDayTarifChoiceForm = Form.create({ name: 'OneDayTarifChoiceForm' })(
  OneDayTarifChoice,
);
export default OneDayTarifChoiceForm;
