import { connect } from 'react-redux';

import { Creators } from 'services/redux/buyVignette/actions';
import AnnualTarifChoices from './components';

const mapStateToProps = state => {
  return {
    isRequesting: state.buyVignet.isGettingTarifChoices,
    data: state.buyVignet.tarifChoices,
    error: state.buyVignet.error,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getTarifChoices: data => {
      dispatch(Creators.buyVignetGetTarifChoicesRequest(data));
    },
    clearData: () => {
      dispatch(Creators.buyVignetClearTarifChoices());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AnnualTarifChoices);
