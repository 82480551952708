import { connect } from 'react-redux';
import { Creators } from '../../services/redux/auth/actions';
import ActivateVignetteSteps from './components';
import { history } from 'util/helpers/history';

const mapStateToProps = state => {
  const { isloading, error, user } = state.auth;
  return {
    isloading,
    error,
    user,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    gotToActivateVignette: () => {
      history.push('/activate/vignette');
    },
    gotToHome: () => {
      history.push('/');
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ActivateVignetteSteps);
