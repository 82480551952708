import { connect } from 'react-redux';

import { Creators as StepCreators } from '../../../../services/redux/extendVignetteSteps/actions';
import { Creators } from 'services/redux/buyVignette/actions';
import BuyPaymentChoice from './components';

const mapStateToProps = state => {
  return {
    isGettingVignetCost: state.buyVignet.isGettingVignetCost,
    vignetCostError: state.buyVignet.vignetCostError,
    vignetCost: state.buyVignet.vignetCost,
    isGettingInstallment: state.buyVignet.isGettingInstallment,
    installmentError: state.buyVignet.installmentError,
    installment: state.buyVignet.installments,
    creatingInstallment: state.buyVignet.creatingInstallment,
    creatingDirect: state.buyVignet.creatingDirect,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateRoute: route => {
      dispatch(StepCreators.updateBuyVignetteRoute(route));
    },
    getInstallmentData: () => {
      dispatch(Creators.buyVignetGetInstallmentsRequest());
    },
    getVignetCost: () => {
      dispatch(Creators.buyVignetGetVignetCostRequest());
    },
    createInstallment: () => {
      dispatch(Creators.buyVignetCreateInstallmentRequest());
    },
    createDirect: () => {
      dispatch(Creators.buyVignetCreateDirectRequest());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BuyPaymentChoice);
