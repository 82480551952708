import React from 'react';
import Instruction from 'components/Instruction';
import { FormattedMessage } from 'react-intl';

const InitializeExtension = props => {
  return (
    <Instruction
      header={'instruction.extend'}
      subheader={'instruction.extend.subheader'}
      messages={[
        'extend.start.first.instruction',
        'extend.start.second.instruction',
        'extend.start.third.instruction',
        'extend.start.fourth.instruction',
        'extend.start.fifth.instruction',
        'extend.start.sixth.instruction',
        'extend.start.seventh.instruction',
      ]}
      description={{
        title: 'activate.instructions.desc.title',
        bullets: [
          'extend.instructions.bullets.one',
          'extend.instructions.bullets.two',
          'extend.instructions.bullets.three',
        ],
      }}
      iconURL={require('assets/timer_15.svg')}
      iconStyle={{ height: 120, width: 120 }}
      onStart={() => props.goToExtendVerification()}
    />
  );
};

export default InitializeExtension;
