import { apiRequest } from '../apiRequest';
import { BSN_VERIFICATION_ENDPOINT, GET_VIGNET_COST_ENDPOINT } from '../urls';

export const extendVerifyVignet = data => {
  return apiRequest('POST', BSN_VERIFICATION_ENDPOINT, data);
};

export const extendGetVignetCost = data => {
  return apiRequest('POST', GET_VIGNET_COST_ENDPOINT, data);
};
