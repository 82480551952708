import { createTypes } from 'reduxsauce';

export default createTypes(
  `
  EXTEND_VERIFICATION_REQUEST
  EXTEND_VERIFICATION_SUCCESS
  EXTEND_VERIFICATION_FAILURE

  EXTEND_GET_VIGNET_COST_REQUEST
  EXTEND_GET_VIGNET_COST_SUCCESS
  EXTEND_GET_VIGNET_COST_FAILURE
`,
  {},
);
