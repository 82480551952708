import React from 'react';
import { Row, Spin, Form, Input, Select, Checkbox } from 'antd';
import { injectIntl } from 'react-intl';

const VehicleDataForm = props => {
  const {
    isLoading,
    vesselTypes = [],
    isError,
    help,
    onSubmit,
    intl,
    form,
  } = props;

  const { getFieldDecorator } = form;

  if (isLoading) {
    return (
      <Row type="flex" justify="center">
        <Spin />
      </Row>
    );
  }

  return (
    <Form onSubmit={onSubmit} className="forgot-pin-form" align="start">
      <Form.Item label={intl.formatMessage({ id: 'vessel.name' })}>
        {getFieldDecorator('object_name', {
          rules: [
            {
              required: true,
              message: intl.formatMessage({
                id: 'errors.enter.vessel.name',
              }),
            },
          ],
        })(<Input type="text" />)}
      </Form.Item>

      <Form.Item label={intl.formatMessage({ id: 'vessel.type' })}>
        {getFieldDecorator('object_type', {
          rules: [
            {
              required: true,
              message: intl.formatMessage({
                id: 'errors.select.vessel.type',
              }),
            },
          ],
        })(
          <Select>
            {vesselTypes.map(({ id, name }, index) => {
              return (
                <Select.Option key={index} value={id}>
                  {name}
                </Select.Option>
              );
            })}
          </Select>,
        )}
      </Form.Item>

      <Form.Item>
        {getFieldDecorator('checked', {
          valuePropName: 'checked',
          initialValue: false,
        })(
          <Checkbox>
            {intl.formatMessage({ id: 'vessel.data.confirmation' })}
          </Checkbox>,
        )}
        {!form.getFieldValue('checked') && (
          <Form.Item
            help={intl.formatMessage({ id: 'help.confirm.this' })}
            validateStatus="error"
          ></Form.Item>
        )}
      </Form.Item>

      {isError && <Form.Item help={help} validateStatus="error"></Form.Item>}
    </Form>
  );
};

export default injectIntl(VehicleDataForm);
