import { connect } from 'react-redux';

import { Creators as StepsCreators } from '../../../../services/redux/extendVignetteSteps/actions';
import { Creators } from '../../../../services/redux/extendVignette/actions';
import AccountForm from './components';

const mapStateToProps = state => {
  return {
    error: state.extendVignetData.accountError,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateRoute: route => {
      dispatch(StepsCreators.updateExtendVignetteRoute(route));
    },
    updateCredentials: (email, password) => {
      dispatch(Creators.extendUpdateCredentialsRequest(email, password));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountForm);
