import { connect } from 'react-redux';

import { Creators as StepsCreators } from '../../../../services/redux/extendVignetteSteps/actions';
import { Creators } from '../../../../services/redux/extendVignette/actions';
import PartPaymentAgreement from './components';

const mapStateToProps = state => {
  return {
    installment: state.extendVignetData.installment,
    isLoading: state.extendVignetData.isCreatingInstallments,
    errorMsg: state.extendVignetData.isCreatingInstallmentsError,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateRoute: route => {
      dispatch(StepsCreators.updateExtendVignetteRoute(route));
    },
    createInstallments: () => {
      dispatch(Creators.extendCreateInstallmentsRequest());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PartPaymentAgreement);
