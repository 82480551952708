import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Alert, Form, Modal, Button } from 'antd';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import moment from 'moment';

import Title from 'components/Title';
import { DELAY_SECONDS, DATE_FORMAT } from 'constants/index';
import './styles.scss';
import StepCard from 'components/StepCard';
import { history } from 'util/helpers/history';
import { formatAmountToNL } from 'util/helpers/normalizer';

let timer;

class AnnualPaymentChoice extends Component {
  state = {
    visible: true,
    showModal: false,
  };
  componentDidMount() {
    history.location.state = {};
    this.props.getInstallmentData();
    this.props.getVignetCost();
  }

  getVignetCost = () => {
    return this.props.vignetCost
      ? formatAmountToNL(this.props.vignetCost.vignet_cost)
      : null;
  };

  canClickInstallment = () => {
    return true;
  };

  getErrorMsg = pathname => {
    if (pathname.substring(36) === 'display') {
      return false;
    } else if (pathname.substring(36) === 'cancel') {
      return 'extend.step.payment.mandate.cancel.message';
    } else if (pathname.substring(36) === 'error') {
      return 'extend.step.payment.mandate.error.message';
    } else if (pathname.substring(36) === 'reject') {
      return 'extend.step.payment.mandate.reject.message';
    }
  };

  renderSuccessMessage = () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      this.setState({ visible: false });
    }, DELAY_SECONDS);
    if (this.state.visible && !this.getErrorMsg(history.location.pathname)) {
      return (
        <div className="spacing">
          <Alert
            type="success"
            message={
              <FormattedMessage id="extend.step.payment.mandate.success.message" />
            }
          />
        </div>
      );
    }
  };

  renderInstallment = () => {
    const { installment } = this.props;
    return (
      <React.Fragment>
        <img
          alt="cancel vignette"
          className="main-icons"
          src={require('assets/instalment.svg')}
          onClick={() => this.setState({ showModal: true, installment: true })}
        />
        <p className="payment">
          <FormattedMessage id="buy.vignet.payment.installment" />
        </p>
        {installment &&
        installment.installments &&
        installment.installments.length ? (
          <>
            <p>
              <span className="amount">
                &#8364;&nbsp;
                {formatAmountToNL(
                  installment.installments
                    .map(d => d.amount)
                    .reduce((a, b) => a + b),
                )}
              </span>{' '}
              {/* (
              <span>
                <FormattedMessage id="extend.step.payment.choice.admin.cost" />
              </span>
              ) */}
            </p>
            <div className="installment-wrapper">
              <table border={1} className="installment-table">
                <tr>
                  <th className="payment-installment-header">
                    <FormattedMessage id="extend.installment.period" />
                  </th>
                  <th className="payment-installment-header">
                    <FormattedMessage id="extend.installment.amount" />
                  </th>
                  <th className="payment-installment-header">
                    <FormattedMessage id="extend.installmennt.date" />
                  </th>
                </tr>
                {installment &&
                installment.installments &&
                installment.installments.length
                  ? installment.installments.map((data, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>&#8364;&nbsp;{formatAmountToNL(data.amount)}</td>
                        <td>{moment(data.date).format(DATE_FORMAT)}</td>
                      </tr>
                    ))
                  : null}
              </table>
            </div>
          </>
        ) : null}
      </React.Fragment>
    );
  };

  renderCantDoInstallment = () => {
    const { installment } = this.props;
    return (
      <React.Fragment>
        <img
          alt="cancel vignette"
          className="main-icons"
          src={require('assets/part.svg')}
          style={{ opacity: 0.5 }}
        />
        <p className="payment" style={{ opacity: 0.5 }}>
          <FormattedMessage id="buy.vignet.payment.installment" />
        </p>
        {installment &&
        installment.installments &&
        installment.installments.length ? (
          <>
            <p>
              <span className="amount" style={{ opacity: 0.5 }}>
                &#8364;&nbsp;
                {formatAmountToNL(
                  installment.installments
                    .map(d => d.amount)
                    .reduce((a, b) => a + b),
                )}
              </span>{' '}
              <span style={{ opacity: 0.5 }}>
                (<FormattedMessage id="extend.step.payment.choice.admin.cost" />
                )
              </span>
            </p>
          </>
        ) : null}
      </React.Fragment>
    );
  };

  renderModal = () => {
    const documents = JSON.parse(sessionStorage.getItem('tarifChoices'));
    return (
      <Modal
        visible={this.state.showModal}
        onCancel={() => this.setState({ showModal: false })}
        footer={null}
        width={720}
      >
        <div className="modal-wrapper">
          <p className="modal-title">
            <FormattedMessage id="confirmation" />
          </p>
          <p className="modal-instruction">
            <FormattedHTMLMessage id="buy.vignet.payment.modal.description" />
          </p>
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <Button
              type="primary"
              onClick={() => {
                this.setState({ showModal: false });
                if (this.state.installment) {
                  this.props.createInstallment();
                } else {
                  if (documents.documents && documents.documents.length) {
                    this.props.createDirect();
                  } else {
                    history.push('/buy/vignette/annual/direct/payment');
                  }
                }
              }}
            >
              <FormattedMessage id="CONFIRM" />
            </Button>
          </div>
        </div>
      </Modal>
    );
  };
  render() {
    history.location.state = {};
    const documents = JSON.parse(sessionStorage.getItem('tarifChoices'));
    return (
      <StepCard
        id="buy.vignete.instruction.title"
        steps={7}
        current={5}
        className="payment-choice-extend"
        isLoading={
          this.props.isGettingVignetCost ||
          this.props.isGettingInstallment ||
          this.props.creatingInstallment ||
          this.props.creatingDirect
        }
        isNextLoading={
          this.props.creatingInstallment || this.props.creatingDirect
        }
      >
        <Title id="buy.vinget.instruction.item.6" />
        <Row type="flex" justify="center">
          <Col xs={24} lg={24} md={24} sm={24}>
            {/* <p>
              <FormattedMessage id="buy.vignet.payment.choice.description.disable" />
            </p> */}
          </Col>
          <Col xs={24} lg={8} md={8} sm={24} className="align-payment-center">
            <img
              alt="renew vignette"
              className="main-icons"
              src={require('assets/one_time_payment.svg')}
              onClick={() => {
                this.setState({ showModal: true, installment: false });
              }}
              style={{ height: '100px', width: '50px' }}
            />
            <p className="payment">
              <FormattedMessage id="buy.vignet.payment.once" />
            </p>
            <p className="amount">&#8364;&nbsp;{this.getVignetCost()}</p>
          </Col>
          <Col lg={2} className="img-border-divider"></Col>
          <Col xs={24} lg={8} md={8} sm={24} className="align-payment-center">
            {this.canClickInstallment()
              ? this.renderInstallment()
              : this.renderCantDoInstallment()}
          </Col>
        </Row>
        <Row gutter={24} type="flex" justify="center">
          {this.renderSuccessMessage()}
          {this.renderModal()}
          {this.getErrorMsg(history.location.pathname) && this.state.visible ? (
            <Alert
              type="error"
              message={
                <FormattedMessage
                  id={this.getErrorMsg(history.location.pathname)}
                />
              }
            />
          ) : null}
        </Row>
      </StepCard>
    );
  }
}

AnnualPaymentChoice.propTypes = {
  isloading: PropTypes.bool,
  goToActivateVignette: PropTypes.func.isRequired,
  goToExtendVignette: PropTypes.func.isRequired,
};

export default AnnualPaymentChoice;
