import React from 'react';
import { connect } from 'react-redux';
import { Creators } from 'services/redux/buyVignette/actions';
import Success from 'components/Success';
import { history } from 'util/helpers/history';

const BuyVignetSuccessful = props => {
  sessionStorage.removeItem('request_number');
  history.location.state = {};
  props.confirm();

  return (
    <Success
      variable={props.variable}
      id={props.id}
      titleId={props.titleId}
      disabled
    />
  );
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    confirm: () => {
      dispatch(Creators.buyVignetConfirmationRequest());
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BuyVignetSuccessful);
