export const EXTEND_VIGNETTE_ROUTE = {
  BLANK_PAGE: 'blankPage',
  VERIFICATION_STEP: 'verification',
  RATE_STEP: 'rate',
  CHOICE_ROUTE: 'choice',
  SIGNOUT_ROUTE: 'signout',
  CANCELLED_ROUTE: 'cancel',
  PERSONAL_INFO_STEP: 'personal',
  ACCOUNT_STEP: 'account',
  PAYMENT_INSTRUCTION_STEP: 'payment_instruction_step',
  PAYMENT_CHOICE: 'payment_choice',
  PAYMENT_MANDATE: 'payment_mandate',
  PAYMENT_IDEAL: 'payment_ideal',
  FULL_PAYMENT_AGREEMENT: 'full_payment_agreement',
  FULL_PAYMENT_SUCCESSFUL: 'full_payment_successful',
  PART_PAYMENT_AGREEMENT: 'part_payment_agreement',
};

export const BUY_VIGNNETTE_ROUTE = {
  INSTRUCTION_ROUTE: 'instruction_route',
  VIGNET_INFO_ROUTE: 'vignet_info_route',
  TARIF_CHOICES_ROUTE: 'tarif_choices_route',
  CHOOSE_ACCOUNT_ROUTE: 'choose_account_route',
  ACCOUNT_CREATION_ROUTE: 'account_creation_route',
  LOGIN_ROUTE: 'login_route',
  PERSONAL_DETAILS_ROUTE: 'personal_details_route',
  UPLOAD_FILES_ROUTE: 'upload_files_route',
  OVERVIEW_ROUTE: 'overview_route',
  PAYMENT_AGREEMENT: 'payment_agreement',
  PAYMENT_SUCCESSFUL_ROUTE: 'payment_successful_route',
  PAYMENT_SUCCESSFUL_ROUTE_NO_DOCUMENT: 'payment_succsessful_no_document_route',
  DIRECT_PAYMENT: 'direct_payment',
  MANDATE_PAYMENT: 'mandate_payment',
  PAYMENT_CHOICE: 'payment_choice',
  ONE_DAY_VIGNET: {
    INSTRUCTION_ROUTE: 'one_day_instruction_route',
    PERSONAL_INFO_ROUTE: 'one_day_personal_info_route',
    TARIF_CHOICE_ROUTE: 'one_day_tarif_choice_route',
    SUCCSESS_ROUTE: 'one_day_success_route',
    OVERVIEW_ROUTE: 'one_day_overview_route',
    DIRECT_PAYMENT: 'one_day_direct_payment',
  },
};

export const DEV_URL = 'http://localhost:3000';
export const STAGING_URL = 'http://159.65.69.163:3000';
export const PRODUCTION_URL = '';
const PROTOCOL = window.location.protocol;
const PORT = window.location.port;
const HOSTNAME = window.location.hostname;
export const CURRENT_URL = PORT
  ? `${PROTOCOL}//${HOSTNAME}:${PORT}`
  : `${PROTOCOL}//${HOSTNAME}`;

export const DELAY_SECONDS = 10000;
export const DATE_FORMAT = 'DD-MM-YYYY';
