import { call, put, takeLeading } from 'redux-saga/effects';

import { Creators, Types } from '../../redux/extendVignette/actions';
import { Creators as StepCreators } from '../../redux/extendVignetteSteps/actions';
import {
  extendGetOwnerDetails as extendGetOwnerDetailsApi,
  extendUpdateOwnerDetails as extendUpdateOwnerDetailsApi,
  extendUpdateCredentials as extendUpdateCredentialsApi,
  extendGetPaymentInstallment as extendGetPaymentInstallmentApi,
  extendGetPaymentBankLists as extendGetPaymentBankListsAPi,
  extendPaymentMandate as extendPaymentMandateApi,
  extendPaymentDirect as extendPaymentDirectApi,
  extendUpdateObjectLocation as extendUpdateObjectLocationApi,
  extendPaymentHasMandate as extendPaymentHasMandateApi,
  extendCreateInstallments as extendCreateInstallmentsApi,
} from '../../api/extendVignette';
import { EXTEND_VIGNETTE_ROUTE, CURRENT_URL } from 'constants/index';

export function* getUserInfo(actions) {
  try {
    const { customer_id } = actions;
    const response = yield call(extendGetOwnerDetailsApi, { customer_id });
    yield put(Creators.extendGetCustomerSuccess(response.data));
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.extendGetCustomerFailure(error_message));
  }
}

export function* updateUserInfo(actions) {
  try {
    const { data } = actions;
    const response = yield call(extendUpdateOwnerDetailsApi, data);
    yield put(Creators.extendUpdateCustomerSuccess(response.data));
    yield put(
      StepCreators.updateExtendVignetteRoute(
        EXTEND_VIGNETTE_ROUTE.ACCOUNT_STEP,
      ),
    );
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.extendUpdateCustomerFailure(error_message));
  }
}

export function* updateCredentials(actions) {
  try {
    const { email, password } = actions;
    const response = yield call(extendUpdateCredentialsApi, {
      email,
      password,
    });
    yield put(Creators.extendUpdateCredentialsSuccess(response.data));
    yield put(Creators.extendPaymentHasMandateRequest());
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.extendUpdateCredentialsFailure(error_message));
  }
}

export function* getPaymentInstallment(actions) {
  try {
    const { email, password } = actions;
    const response = yield call(extendGetPaymentInstallmentApi, {
      email,
      password,
    });
    yield put(Creators.extendGetPaymentInstallmentSuccess(response.data));
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.extendGetPaymentInstallmentFailure(error_message));
  }
}

export function* getPaymentBankLists(actions) {
  try {
    const { list_type } = actions;
    const response = yield call(extendGetPaymentBankListsAPi, {
      list_type,
    });
    yield put(Creators.extendGetPaymentBankListsSuccess(response.data));
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.extendGetPaymentBankListsFailure(error_message));
  }
}

export function* paymentMandate(actions) {
  try {
    const { issuer } = actions;
    const response = yield call(extendPaymentMandateApi, {
      issuer,
      return_url_success: `${CURRENT_URL}/extend/vignette/payment/mandate/`,
      return_url_cancel: `${CURRENT_URL}/extend/vignette/payment/mandate-cancel/`,
      return_url_error: `${CURRENT_URL}/extend/vignette/payment/mandate-error/`,
      return_url_reject: `${CURRENT_URL}/extend/vignette/payment/mandate-reject/`,
    });
    yield put(Creators.extendGetPaymentMandateSuccess(response.data));
    window.location = response.data.redirect_url;
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.extendGetPaymentMandateFailure(error_message));
  }
}

export function* paymentDirect(actions) {
  try {
    const { issuer } = actions;
    const response = yield call(extendPaymentDirectApi, {
      issuer,
      return_url_success: `${CURRENT_URL}/extend/vignette/payment/direct`,
      return_url_cancel: `${CURRENT_URL}/extend/vignette/payment/direct-cancel`,
      return_url_error: `${CURRENT_URL}/extend/vignette/payment/direct-error`,
      return_url_reject: `${CURRENT_URL}/extend/vignette/payment/direct-reject`,
    });
    yield put(Creators.extendGetPaymentDirectSuccess(response.data));
    window.location = response.data.redirect_url;
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.extendGetPaymentDirectFailure(error_message));
  }
}

export function* updateObjectLocation(actions) {
  try {
    const { object_location } = actions;
    const response = yield call(extendUpdateObjectLocationApi, {
      object_location,
    });
    yield put(Creators.extendUpdateObjectLocationSuccess(response.data));
    yield put(
      StepCreators.updateExtendVignetteRoute(
        EXTEND_VIGNETTE_ROUTE.CHOICE_ROUTE,
      ),
    );
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.extendUpdateObjectLocationFailure(error_message));
  }
}

export function* paymentHasMandate(actions) {
  try {
    const response = yield call(extendPaymentHasMandateApi);
    yield put(Creators.extendPaymentHasMandateSuccess(response.data));
    if (response.data.has_mandate) {
      yield put(
        StepCreators.updateExtendVignetteRoute(
          EXTEND_VIGNETTE_ROUTE.PAYMENT_CHOICE,
        ),
      );
    } else {
      yield put(
        StepCreators.updateExtendVignetteRoute(
          EXTEND_VIGNETTE_ROUTE.PAYMENT_INSTRUCTION_STEP,
        ),
      );
    }
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.extendPaymentHasMandateFailure(error_message));
  }
}

export function* createInstallments(actions) {
  try {
    const { issuer } = actions;
    const response = yield call(extendCreateInstallmentsApi, {
      issuer,
      return_url_success: `${CURRENT_URL}/extend/vignette/payment/installments/`,
      return_url_cancel: `${CURRENT_URL}/extend/vignette/payment/installments-cancel/`,
      return_url_error: `${CURRENT_URL}/extend/vignette/payment/installments-error/`,
      return_url_reject: `${CURRENT_URL}/extend/vignette/payment/installments-reject/`,
    });
    yield put(Creators.extendCreateInstallmentsSuccess(response.data));
    yield put(
      StepCreators.updateExtendVignetteRoute(
        EXTEND_VIGNETTE_ROUTE.FULL_PAYMENT_SUCCESSFUL,
      ),
    );
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.extendCreateInstallmentsFailure(error_message));
  }
}

export function* watchExtendGetUserInfo() {
  yield takeLeading(Types.EXTEND_GET_CUSTOMER_REQUEST, getUserInfo);
}

export function* watchExtendUpdateUserInfo() {
  yield takeLeading(Types.EXTEND_UPDATE_CUSTOMER_REQUEST, updateUserInfo);
}

export function* watchExtendUpdateCredentials() {
  yield takeLeading(Types.EXTEND_UPDATE_CREDENTIALS_REQUEST, updateCredentials);
}

export function* watchExtendGetPaymentInstallment() {
  yield takeLeading(
    Types.EXTEND_GET_PAYMENT_INSTALLMENT_REQUEST,
    getPaymentInstallment,
  );
}

export function* watchGetPaymentBankLists() {
  yield takeLeading(
    Types.EXTEND_GET_PAYMENT_BANK_LISTS_REQUEST,
    getPaymentBankLists,
  );
}

export function* watchGetPaymentMandate() {
  yield takeLeading(Types.EXTEND_GET_PAYMENT_MANDATE_REQUEST, paymentMandate);
}

export function* watchGetPaymentDirect() {
  yield takeLeading(Types.EXTEND_GET_PAYMENT_DIRECT_REQUEST, paymentDirect);
}

export function* watchUpdateObjectLocation() {
  yield takeLeading(
    Types.EXTEND_UPDATE_OBJECT_LOCATION_REQUEST,
    updateObjectLocation,
  );
}

export function* watchPaymentHasMandate() {
  yield takeLeading(
    Types.EXTEND_PAYMENT_HAS_MANDATE_REQUEST,
    paymentHasMandate,
  );
}

export function* watchCreateInstallments() {
  yield takeLeading(
    Types.EXTEND_CREATE_INSTALLMENTS_REQUEST,
    createInstallments,
  );
}
