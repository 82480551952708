import { connect } from 'react-redux';

import { Creators } from 'services/redux/buyVignette/actions';
import AnnualOverview from './components';

const mapStateToProps = state => {
  return {
    isGettingOverview: state.buyVignet.isGettingOverview,
    overview: state.buyVignet.overview,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getOverview: () => {
      dispatch(Creators.buyVignetGetOverviewRequest());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AnnualOverview);
