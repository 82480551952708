import { connect } from 'react-redux';

import { Creators } from 'services/redux/buyVignette/actions';
import Payment from './components';

const mapStateToProps = state => {
  return {
    banks: state.buyVignet.banks,
    isFetchingBanks: state.buyVignet.isGettingBanks,
    isMakingDayPayment: state.buyVignet.isMakingDayPayment,
    makingDayPaymentError: state.buyVignet.makingDayPaymentError,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getBankLists: data => {
      dispatch(Creators.buyVignetGetBankListsRequest(data));
    },
    makeDayPayment: issuer => {
      dispatch(Creators.buyVignetMakeDayPaymentRequest(issuer));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
