import axios from 'axios';
import { BASE_URL } from './urls';

export const getToken = () => {
  //get token from localstorage
  let token = sessionStorage.getItem('access_token');
  return token;
};

export const refreshToken = () => {};

export const axiosInstance = token => {
  let AUTH_TOKEN = getToken() || '';
  if (token) {
    AUTH_TOKEN = token;
  }
  const instance = axios.create({
    baseURL: BASE_URL,
    headers: {
      Accept: 'application/json',
      ...(AUTH_TOKEN && {
        Authorization: 'Bearer ' + AUTH_TOKEN,
      }),
    },
  });

  // maybe we can add an interceptor, which checks if token is expired and refreshes it.

  return instance;
};
