import { connect } from 'react-redux';

import { Creators } from 'services/redux/buyVignette/actions';
import PersonalInfoForm from './components';

const mapStateToProps = state => {
  return {
    customer: state.buyVignet.owner,
    isUpdatingOwner: state.buyVignet.isUpdatingOwner,
    updateError: state.buyVignet.updateError,
    isGettingInfo: state.buyVignet.isGettingOwner,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getOwner: () => {
      dispatch(Creators.buyVignetGetOwnerRequest());
    },
    updateCustomer: data => {
      dispatch(Creators.buyVignetUpdateOwnerRequest(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfoForm);
