import { connect } from 'react-redux';

import { history } from 'util/helpers/history';
import BuyVignetChoice from './components';

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    goToBuyVignetteSteps: () => {
      history.push('/buy/vignette/annual');
    },
    goToOneDayInstruction: () => {
      history.push('/buy/vignette/one-day/');
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BuyVignetChoice);
