import { createTypes } from 'reduxsauce';

export default createTypes(
  `
  CANCEL_VIGNET_REQUEST
  CANCEL_VIGNET_SUCCESS
  CANCEL_VIGNET_FAILURE

`,
  {},
);
