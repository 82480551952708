import { connect } from 'react-redux';

import { Creators as StepsCreators } from '../../../../services/redux/extendVignetteSteps/actions';
import PaymentInstruction from './components';

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    updateRoute: route => {
      dispatch(StepsCreators.updateExtendVignetteRoute(route));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentInstruction);
