import React from 'react';
import Success from 'components/Success';
import { history } from 'util/helpers/history';
import { logOut } from 'util/helpers/normalizer';

const FullPaymentSuccessFull = props => {
  history.location.state = {};
  logOut();
  return (
    <Success
      id="extend.step.payment.successful"
      titleId="extend.step.personal.title"
    />
  );
};

export default FullPaymentSuccessFull;
