import React from 'react';
import { DELAY_SECONDS } from 'constants/index';
import StepCard from 'components/StepCard';
import Title from 'components/Title';
import { Form, Row, Col, Checkbox, Alert } from 'antd';
import { FormattedMessage } from 'react-intl';
import './styles.scss';
import { history } from 'util/helpers/history';

let timer;
class OverView extends React.Component {
  state = {
    visible: true,
  };
  componentDidMount() {
    if (this.props.getOverview) {
      this.props.getOverview();
    }
  }
  handleSubmit = e => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onNext();
      }
    });
  };

  checkCheckBox = (rule, value, callback) => {
    if (!value) {
      callback(<FormattedMessage id="help.confirm.this" />);
    } else {
      callback();
    }
  };

  renderCheckBoxes = () => {
    const { getFieldDecorator } = this.props.form;
    return (
      <React.Fragment>
        <Col lg={24} sm={24} md={24} style={{ textAlign: 'left' }}>
          <Form.Item style={{ margin: '0px', padding: '0px' }}>
            {getFieldDecorator('checkbox', {
              valuePropName: 'checked',
              rules: [{ validator: this.checkCheckBox }],
            })(
              <Checkbox>
                <span>
                  <FormattedMessage id="request.overview.checkbox.message" />
                </span>
              </Checkbox>,
            )}
          </Form.Item>
        </Col>
        <Col lg={24} sm={24} md={24} style={{ textAlign: 'left' }}>
          <Form.Item style={{ margin: '0px', padding: '0px' }}>
            {getFieldDecorator('checkbox2', {
              valuePropName: 'checked',
              rules: [{ validator: this.checkCheckBox }],
            })(
              <Checkbox>
                <span>
                  <FormattedMessage id="request.overview.checkbox.message.2" />
                  &nbsp;
                  {this.props.oneDay ? (
                    <FormattedMessage id="filled_in" />
                  ) : null}
                </span>
              </Checkbox>,
            )}
          </Form.Item>
        </Col>
      </React.Fragment>
    );
  };

  renderServerErrorMsg = errorMsg => {
    if (errorMsg) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        this.setState({ visible: false });
      }, DELAY_SECONDS);
      if (this.state.visible) {
        return (
          <div className="error-msg">
            <Alert description={errorMsg} type="error" />
          </div>
        );
      }
    }
  };

  render() {
    history.location.state = {};
    return (
      <Form onSubmit={this.handleSubmit}>
        <StepCard
          onPrevious={() => this.props.onPrevious()}
          onNext={this.handleSubmit}
          current={this.props.current}
          steps={this.props.steps}
          id={this.props.title}
          className="buy-vignet-tarif-choices"
          moreDetails={this.renderCheckBoxes()}
          isLoading={this.props.isGettingOverview}
        >
          <Title id="request.overview" />
          <Row gutter={24} className="upload-wrapper">
            <Col
              span={24}
              xs={24}
              lg={20}
              push={1}
              md={24}
              sm={24}
              className="form-wrapper"
            >
              <p className="request-overview-header">
                <FormattedMessage id="request.overview.title" />
              </p>
              <Row>
                {this.props.customer &&
                  this.props.customer.map(data => {
                    if (!data.value) {
                      return;
                    }
                    return (
                      <React.Fragment>
                        <Col
                          span={24}
                          xs={24}
                          lg={12}
                          md={24}
                          sm={24}
                          className="form-wrapper"
                          key={data.id}
                        >
                          <p>
                            <FormattedMessage id={data.id} />
                          </p>
                        </Col>
                        <Col
                          span={24}
                          xs={24}
                          lg={12}
                          md={24}
                          sm={24}
                          className="form-wrapper"
                        >
                          <p className="request-overview-value">{data.value}</p>
                        </Col>
                      </React.Fragment>
                    );
                  })}

                <p className="request-overview-header">
                  <FormattedMessage id="summary.application" />
                </p>

                {this.props.vignetInfo &&
                  this.props.vignetInfo.map(data => {
                    if (!data.value) {
                      return;
                    }
                    return (
                      <React.Fragment>
                        <Col
                          span={24}
                          xs={24}
                          lg={12}
                          md={24}
                          sm={24}
                          className="form-wrapper"
                        >
                          <p>
                            <FormattedMessage id={data.id} />
                          </p>
                        </Col>
                        <Col
                          span={24}
                          xs={24}
                          lg={12}
                          md={24}
                          sm={24}
                          className="form-wrapper"
                        >
                          <p className="request-overview-value">
                            {data.value}{' '}
                            {data.id === 'buy.vignet.tarif.boat.surface' ? (
                              <sup>2</sup>
                            ) : null}
                          </p>
                        </Col>
                      </React.Fragment>
                    );
                  })}
              </Row>
              {this.props.bankError &&
                this.renderServerErrorMsg(this.props.bankError)}
            </Col>
          </Row>
        </StepCard>
      </Form>
    );
  }
}

const OverViewForm = Form.create({ name: 'OverViewForm' })(OverView);

export default OverViewForm;
