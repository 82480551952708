import React from 'react';

import Verification from './Verification';
import { EXTEND_VIGNETTE_ROUTE } from 'constants/index';
import Rate from './Rate';
import Choice from './Choice';
import SignOut from './SignOut';
import ExtendVignetteCancellationCompleted from './CancelVignette';
import CustomerInfoForm from './CustomerInfo';
import Account from './Account';
import PaymentInstruction from './PaymentInstruction';
import PaymentChoice from './PaymentChoice';
import FullPaymentAgreement from './FullPaymentAgreement';
import FullPaymentSuccessFull from './FullPaymentSuccess';
import PartPaymentAgreement from './PartPaymentAgreement';
import MandateBankLists from './MandateBankLists';
import DirectMandateForm from './DirectPaymentBank/';
import { history } from 'util/helpers/history';

class ExtendVignetteSteps extends React.Component {
  componentDidMount() {
    if (history && history.location && history.location.state) {
      if (history.location.state.route === 'mandate') {
      } else if (history.location.state.route === 'ideal') {
      }
    }
  }
  getCurrentStepComponent = () => {
    let component = null;

    switch (this.props.route) {
      case EXTEND_VIGNETTE_ROUTE.RATE_STEP:
        component = <Rate />;
        break;

      case EXTEND_VIGNETTE_ROUTE.CHOICE_ROUTE:
        component = <Choice />;
        break;

      case EXTEND_VIGNETTE_ROUTE.SIGNOUT_ROUTE:
        component = <SignOut />;
        break;

      case EXTEND_VIGNETTE_ROUTE.CANCELLED_ROUTE:
        component = <ExtendVignetteCancellationCompleted />;
        break;

      case EXTEND_VIGNETTE_ROUTE.PERSONAL_INFO_STEP:
        component = <CustomerInfoForm />;
        break;

      case EXTEND_VIGNETTE_ROUTE.ACCOUNT_STEP:
        component = <Account />;
        break;

      case EXTEND_VIGNETTE_ROUTE.PAYMENT_INSTRUCTION_STEP:
        component = <PaymentInstruction />;
        break;

      case EXTEND_VIGNETTE_ROUTE.PAYMENT_MANDATE:
        component = <MandateBankLists />;
        break;

      case EXTEND_VIGNETTE_ROUTE.PAYMENT_IDEAL:
        component = <DirectMandateForm />;
        break;

      case EXTEND_VIGNETTE_ROUTE.PAYMENT_CHOICE:
        component = <PaymentChoice />;
        break;

      case EXTEND_VIGNETTE_ROUTE.FULL_PAYMENT_AGREEMENT:
        component = <FullPaymentAgreement />;
        break;

      case EXTEND_VIGNETTE_ROUTE.FULL_PAYMENT_SUCCESSFUL:
        component = <FullPaymentSuccessFull />;
        break;

      case EXTEND_VIGNETTE_ROUTE.PART_PAYMENT_AGREEMENT:
        component = <PartPaymentAgreement />;
        break;

      default:
        component = <Verification />;
        break;
    }

    if (history && history.location && history.location.state) {
      if (history.location.state.route === 'mandate') {
        component = <PaymentChoice />;
      } else if (history.location.state.route === 'ideal') {
        component = <FullPaymentSuccessFull />;
      } else if (history.location.state.route === 'mandate-failed') {
        component = (
          <PaymentInstruction mandateResponseMsg={history.location.state.id} />
        );
      } else if (history.location.state.route === 'direct-failed') {
        component = (
          <FullPaymentAgreement
            mandateResponseMsg={history.location.state.id}
          />
        );
      }
    }

    return component;
  };

  render() {
    return (
      <div className="activate-vignette-steps">
        {this.getCurrentStepComponent()}
      </div>
    );
  }
}

export default ExtendVignetteSteps;
