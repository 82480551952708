import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions(
  {
    extendVerificationRequest: ['vignet_number', 'bsn'],
    extendVerificationSuccess: ['responseData'],
    extendVerificationFailure: ['error'],

    extendGetVignetCostRequest: ['vignet_id'],
    extendGetVignetCostSuccess: ['responseData'],
    extendGetVignetCostFailure: ['error'],
  },
  {},
);
