import React from 'react';
import StepCard from 'components/StepCard';
import Title from 'components/Title';
import { Form, Input, Row, Col, Alert } from 'antd';
import { FormattedMessage } from 'react-intl';
import { DELAY_SECONDS } from 'constants/index';
import { history } from 'util/helpers/history';

let timer;
class AnnualResetPassword extends React.Component {
  state = {
    visible: true,
  };

  validPassword = (rule, value, callback) => {
    const { form } = this.props;
    const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;
    if (regex.test(value)) {
      callback();
    } else {
      callback(<FormattedMessage id="password.strength.error" />);
    }
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback(<FormattedMessage id="passWordNotMatch" />);
    } else {
      callback();
    }
  };

  handleSubmit = e => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const data = {
          token: history.location.pathname.substr(27),
          password: values.password,
        };
        this.props.resetPassword(data);
      }
    });
  };

  renderServerErrorMsg = () => {
    const { error } = this.props;
    if (error) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        this.setState({ visible: false });
      }, DELAY_SECONDS);
      if (this.state.visible) {
        return (
          <div className="error-msg">
            <Alert description={error} type="error" />
          </div>
        );
      }
    }
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <StepCard
          onNext={this.handleSubmit}
          className="buy-vignet-tarif-choices"
          isNextLoading={this.props.isResetting}
        >
          <Title id="buy.vignet.reset.password" />
          <Row type="flex" justify="space-around" align="middle">
            <Col
              span={24}
              xs={24}
              lg={8}
              md={24}
              sm={24}
              className="form-wrapper"
            >
              <label>
                <FormattedMessage id="buy.vignet.make.password" />*
              </label>
              <Form.Item>
                {getFieldDecorator('password', {
                  rules: [
                    {
                      required: true,
                      message: <FormattedMessage id="required.error" />,
                    },
                    {
                      validator: this.validPassword,
                    },
                  ],
                })(<Input type="password" />)}
              </Form.Item>
              <label>
                <FormattedMessage id="buy.vignet.retype.password" />*
              </label>
              <Form.Item>
                {getFieldDecorator('confirm_password', {
                  rules: [
                    {
                      required: true,
                      message: <FormattedMessage id="required.error" />,
                    },
                    {
                      validator: this.compareToFirstPassword,
                    },
                  ],
                })(<Input type="password" />)}
              </Form.Item>
              {this.renderServerErrorMsg()}
            </Col>
          </Row>
        </StepCard>
      </Form>
    );
  }
}

const AnnualResetPasswordForm = Form.create({
  name: 'AnnualResetPasswordForm',
})(AnnualResetPassword);

export default AnnualResetPasswordForm;
