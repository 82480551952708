const COUNTRY_CODE = [
  {
    name: 'Afghanistan',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Afghanistan.svg',
    number: '+93',
  },
  {
    name: 'Albania',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/3/36/Flag_of_Albania.svg',
    number: '+355',
  },
  {
    name: 'Algeria',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_Algeria.svg',
    number: '+213',
  },
  {
    name: 'Andorra',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Andorra.svg',
    number: '+376',
  },
  {
    name: 'Angola',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/9/9d/Flag_of_Angola.svg',
    number: '+244',
  },
  {
    name: 'Anguilla',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/b/b4/Flag_of_Anguilla.svg',
    number: '+1 264',
  },
  {
    name: 'Antigua and Barbuda',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/8/89/Flag_of_Antigua_and_Barbuda.svg',
    number: '+1268',
  },
  {
    name: 'Argentina',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/1/1a/Flag_of_Argentina.svg',
    number: '+54',
  },
  {
    name: 'Armenia',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/2/2f/Flag_of_Armenia.svg',
    number: '+374',
  },
  {
    name: 'Aruba',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/f/f6/Flag_of_Aruba.svg',
    number: '+297',
  },
  {
    name: 'Australia',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/8/88/Flag_of_Australia_%28converted%29.svg',
    number: '+61',
  },
  {
    name: 'Austria',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/4/41/Flag_of_Austria.svg',
    number: '+43',
  },
  {
    name: 'Azerbaijan',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/d/dd/Flag_of_Azerbaijan.svg',
    number: '+994',
  },
  {
    name: 'Bahamas',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/9/93/Flag_of_the_Bahamas.svg',
    number: '+1 242',
  },
  {
    name: 'Bahrain',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/2/2c/Flag_of_Bahrain.svg',
    number: '+973',
  },
  {
    name: 'Bangladesh',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/f/f9/Flag_of_Bangladesh.svg',
    number: '+880',
  },
  {
    name: 'Barbados',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/e/ef/Flag_of_Barbados.svg',
    number: '+1 246',
  },
  {
    name: 'Belarus',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/8/85/Flag_of_Belarus.svg',
    number: '+375',
  },
  {
    name: 'Belgium',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/6/65/Flag_of_Belgium.svg',
    number: '+32',
  },
  {
    name: 'Belize',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/e/e7/Flag_of_Belize.svg',
    number: '+501',
  },
  {
    name: 'Benin',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/0/0a/Flag_of_Benin.svg',
    number: '+229',
  },
  {
    name: 'Bermuda',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/b/bf/Flag_of_Bermuda.svg',
    number: '+1 441',
  },
  {
    name: 'Bhutan',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/9/91/Flag_of_Bhutan.svg',
    number: '+975',
  },
  {
    name: 'Bosnia and Herzegovina',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/b/bf/Flag_of_Bosnia_and_Herzegovina.svg',
    number: '+387',
  },
  {
    name: 'Botswana',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_Botswana.svg',
    number: '+267',
  },
  {
    name: 'Bouvet Island',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Norway.svg',
    number: '+55',
  },
  {
    name: 'Brazil',
    flag: 'https://upload.wikimedia.org/wikipedia/en/0/05/Flag_of_Brazil.svg',
    number: '+55',
  },
  {
    name: 'British Indian Ocean Territory',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/6/6e/Flag_of_the_British_Indian_Ocean_Territory.svg',
    number: '+246',
  },
  {
    name: 'Brunei Darussalam',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/9/9c/Flag_of_Brunei.svg',
    number: '+673',
  },
  {
    name: 'Bulgaria',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Bulgaria.svg',
    number: '+359',
  },
  {
    name: 'Burkina Faso',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/3/31/Flag_of_Burkina_Faso.svg',
    number: '+226',
  },
  {
    name: 'Burundi',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/5/50/Flag_of_Burundi.svg',
    number: '+257',
  },
  {
    name: 'Cambodia',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_Cambodia.svg',
    number: '+855',
  },
  {
    name: 'Cameroon',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/4/4f/Flag_of_Cameroon.svg',
    number: '+237',
  },
  {
    name: 'Canada',
    flag: 'https://upload.wikimedia.org/wikipedia/en/c/cf/Flag_of_Canada.svg',
    number: '+1',
  },
  {
    name: 'Cape Verde',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/3/38/Flag_of_Cape_Verde.svg',
    number: '+238',
  },
  {
    name: 'Cayman Islands',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/0/0f/Flag_of_the_Cayman_Islands.svg',
    number: '+1345',
  },
  {
    name: 'Central African Republic',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/6/6f/Flag_of_the_Central_African_Republic.svg',
    number: '+236',
  },
  {
    name: 'Chad',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/4/4b/Flag_of_Chad.svg',
    number: '+235',
  },
  {
    name: 'Chile',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/7/78/Flag_of_Chile.svg',
    number: '+56',
  },
  {
    name: 'China',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_the_People%27s_Republic_of_China.svg',
    number: '+86',
  },
  {
    name: 'Christmas Island',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/6/67/Flag_of_Christmas_Island.svg',
    number: '+61',
  },
  {
    name: 'Cocos (Keeling) Islands',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/7/74/Flag_of_the_Cocos_%28Keeling%29_Islands.svg',
    number: '+61',
  },
  {
    name: 'Colombia',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/2/21/Flag_of_Colombia.svg',
    number: '+57',
  },
  {
    name: 'Comoros',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/9/94/Flag_of_the_Comoros.svg',
    number: '+269',
  },
  {
    name: 'Congo',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/9/92/Flag_of_the_Republic_of_the_Congo.svg',
    number: '+242',
  },
  {
    name: 'Cook Islands',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/3/35/Flag_of_the_Cook_Islands.svg',
    number: '+682',
  },
  {
    name: 'Costa Rica',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Costa_Rica_%28state%29.svg',
    number: '+506',
  },
  {
    name: 'Croatia',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/1/1b/Flag_of_Croatia.svg',
    number: '+385',
  },
  {
    name: 'Cuba',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/b/bd/Flag_of_Cuba.svg',
    number: '+53',
  },
  {
    name: 'Cyprus',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Cyprus.svg',
    number: '+357',
  },
  {
    name: 'Czech Republic',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/c/cb/Flag_of_the_Czech_Republic.svg',
    number: '+420',
  },
  {
    name: 'Denmark',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/9/9c/Flag_of_Denmark.svg',
    number: '+45',
  },
  {
    name: 'Djibouti',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/3/34/Flag_of_Djibouti.svg',
    number: '+253',
  },
  {
    name: 'Dominica',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/c/c4/Flag_of_Dominica.svg',
    number: '+1 767',
  },
  {
    name: 'Dominican Republic',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_the_Dominican_Republic.svg',
    number: '+1 849',
  },
  {
    name: 'Ecuador',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/e/e8/Flag_of_Ecuador.svg',
    number: '+593',
  },
  {
    name: 'Egypt',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Egypt.svg',
    number: '+20',
  },
  {
    name: 'El Salvador',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/3/34/Flag_of_El_Salvador.svg',
    number: '+503',
  },
  {
    name: 'Equatorial Guinea',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/3/31/Flag_of_Equatorial_Guinea.svg',
    number: '+240',
  },
  {
    name: 'Eritrea',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/2/29/Flag_of_Eritrea.svg',
    number: '+291',
  },
  {
    name: 'Estonia',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/8/8f/Flag_of_Estonia.svg',
    number: '+372',
  },
  {
    name: 'Ethiopia',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/7/71/Flag_of_Ethiopia.svg',
    number: '+251',
  },
  {
    name: 'Falkland Islands (Malvinas)',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_the_Falkland_Islands.svg',
    number: '+500',
  },
  {
    name: 'Faroe Islands',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/3/3c/Flag_of_the_Faroe_Islands.svg',
    number: '+298',
  },
  {
    name: 'Fiji',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/b/ba/Flag_of_Fiji.svg',
    number: '+679',
  },
  {
    name: 'Finland',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Finland.svg',
    number: '+358',
  },
  {
    name: 'France',
    flag: 'https://upload.wikimedia.org/wikipedia/en/c/c3/Flag_of_France.svg',
    number: '+33',
  },
  {
    name: 'French Guiana',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/2/29/Flag_of_French_Guiana.svg',
    number: '+594',
  },
  {
    name: 'French Polynesia',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/d/db/Flag_of_French_Polynesia.svg',
    number: '+689',
  },
  {
    name: 'Gabon',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/0/04/Flag_of_Gabon.svg',
    number: '+241',
  },
  {
    name: 'Gambia',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_The_Gambia.svg',
    number: '+220',
  },
  {
    name: 'Georgia',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/0/0f/Flag_of_Georgia.svg',
    number: '+995',
  },
  {
    name: 'Germany',
    flag: 'https://upload.wikimedia.org/wikipedia/en/b/ba/Flag_of_Germany.svg',
    number: '+49',
  },
  {
    name: 'Ghana',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Ghana.svg',
    number: '+233',
  },
  {
    name: 'Gibraltar',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/0/02/Flag_of_Gibraltar.svg',
    number: '+350',
  },
  {
    name: 'Greece',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_Greece.svg',
    number: '+30',
  },
  {
    name: 'Greenland',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/0/09/Flag_of_Greenland.svg',
    number: '+299',
  },
  {
    name: 'Grenada',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Grenada.svg',
    number: '+1 473',
  },
  {
    name: 'Guadeloupe',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/0/04/Flag_of_Guadeloupe_%28local%29.svg',
    number: '+590',
  },
  {
    name: 'Guam',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/0/07/Flag_of_Guam.svg',
    number: '+1 671',
  },
  {
    name: 'Guatemala',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/e/ec/Flag_of_Guatemala.svg',
    number: '+502',
  },
  {
    name: 'Guernsey',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_Guernsey.svg',
    number: '+44',
  },
  {
    name: 'Guinea',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/e/ed/Flag_of_Guinea.svg',
    number: '+224',
  },
  {
    name: 'Guinea-Bissau',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_Guinea-Bissau.svg',
    number: '+245',
  },
  {
    name: 'Guyana',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/9/99/Flag_of_Guyana.svg',
    number: '+592',
  },
  {
    name: 'Haiti',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/5/56/Flag_of_Haiti.svg',
    number: '+509',
  },
  {
    name: 'Heard Island and McDonald Islands',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_Antarctica.svg',
    number: '+672',
  },
  {
    name: 'Holy See (Vatican City State)',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/0/00/Flag_of_the_Vatican_City.svg',
    number: '+379',
  },
  {
    name: 'Honduras',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/8/82/Flag_of_Honduras.svg',
    number: '+504',
  },
  {
    name: 'Hong Kong',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/5/5b/Flag_of_Hong_Kong.svg',
    number: '+852',
  },
  {
    name: 'Hungary',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/c/c1/Flag_of_Hungary.svg',
    number: '+36',
  },
  {
    name: 'Iceland',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/c/ce/Flag_of_Iceland.svg',
    number: '+354',
  },
  {
    name: 'India',
    flag: 'https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg',
    number: '+91',
  },
  {
    name: 'Indonesia',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_Indonesia.svg',
    number: '+62',
  },
  {
    name: 'Iran',
    flag:
      ' https://upload.wikimedia.org/wikipedia/commons/c/ca/Flag_of_Iran.svg',
    number: '+98',
  },
  {
    name: 'Iraq',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/f/f6/Flag_of_Iraq.svg',
    number: '+964',
  },
  {
    name: 'Ireland',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/4/45/Flag_of_Ireland.svg',
    number: '+353',
  },
  {
    name: 'Isle of Man',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_the_Isle_of_Man.svg',
    number: '+44',
  },
  {
    name: 'Israel',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg',
    number: '+972',
  },
  {
    name: 'Italy',
    flag: 'https://upload.wikimedia.org/wikipedia/en/0/03/Flag_of_Italy.svg',
    number: '+39',
  },
  {
    name: 'Jamaica',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/0/0a/Flag_of_Jamaica.svg',
    number: '+1 876',
  },
  {
    name: 'Japan',
    flag: 'https://upload.wikimedia.org/wikipedia/en/9/9e/Flag_of_Japan.svg',
    number: '+81',
  },
  {
    name: 'Jersey',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/1/1c/Flag_of_Jersey.svg',
    number: '+44',
  },
  {
    name: 'Jordan',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/c/c0/Flag_of_Jordan.svg',
    number: '+962',
  },
  {
    name: 'Kazakhstan',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/d/d3/Flag_of_Kazakhstan.svg',
    number: '+7',
  },
  {
    name: 'Kenya',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/4/49/Flag_of_Kenya.svg',
    number: '+254',
  },
  {
    name: 'Kiribati',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/d/d3/Flag_of_Kiribati.svg',
    number: '+686',
  },
  {
    name: 'Kuwait',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/a/aa/Flag_of_Kuwait.svg',
    number: '+965',
  },
  {
    name: 'Kyrgyzstan',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/c/c7/Flag_of_Kyrgyzstan.svg',
    number: '+996',
  },
  {
    name: "Lao People's Democratic Republic",
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/5/56/Flag_of_Laos.svg',
    number: '+856',
  },
  {
    name: 'Latvia',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/8/84/Flag_of_Latvia.svg',
    number: '+371',
  },
  {
    name: 'Lebanon',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/5/59/Flag_of_Lebanon.svg',
    number: '+961',
  },
  {
    name: 'Lesotho',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/4/4a/Flag_of_Lesotho.svg',
    number: '+266',
  },
  {
    name: 'Liberia',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/b/b8/Flag_of_Liberia.svg',
    number: '+231',
  },
  {
    name: 'Liechtenstein',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/4/47/Flag_of_Liechtenstein.svg',
    number: '+423',
  },
  {
    name: 'Lithuania',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Lithuania.svg',
    number: '+370',
  },
  {
    name: 'Luxembourg',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/d/da/Flag_of_Luxembourg.svg',
    number: '+352',
  },
  {
    name: 'Macao',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/6/63/Flag_of_Macau.svg',
    number: '+853',
  },
  {
    name: 'Madagascar',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Madagascar.svg',
    number: '+261',
  },
  {
    name: 'Malawi',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/d/d1/Flag_of_Malawi.svg',
    number: '+265',
  },
  {
    name: 'Malaysia',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/6/66/Flag_of_Malaysia.svg',
    number: '+60',
  },
  {
    name: 'Maldives',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/0/0f/Flag_of_Maldives.svg',
    number: '+960',
  },
  {
    name: 'Mali',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/9/92/Flag_of_Mali.svg',
    number: '+223',
  },
  {
    name: 'Malta',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/7/73/Flag_of_Malta.svg',
    number: '+356',
  },
  {
    name: 'Marshall Islands',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/2/2e/Flag_of_the_Marshall_Islands.svg',
    number: '+692',
  },
  {
    name: 'Martinique',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/5/52/Flag_of_Martinique.svg',
    number: '+596',
  },
  {
    name: 'Mauritania',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/4/43/Flag_of_Mauritania.svg',
    number: '+222',
  },
  {
    name: 'Mauritius',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_Mauritius.svg',
    number: '+230',
  },
  {
    name: 'Mayotte',
    flag: 'https://upload.wikimedia.org/wikipedia/en/c/c3/Flag_of_France.svg',
    number: '+262',
  },
  {
    name: 'Mexico',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/f/fc/Flag_of_Mexico.svg',
    number: '+52',
  },
  {
    name: 'Monaco',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/e/ea/Flag_of_Monaco.svg',
    number: '+377',
  },
  {
    name: 'Mongolia',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/4/4c/Flag_of_Mongolia.svg',
    number: '+976',
  },
  {
    name: 'Montenegro',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/6/64/Flag_of_Montenegro.svg',
    number: '+382',
  },
  {
    name: 'Montserrat',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/d/d0/Flag_of_Montserrat.svg',
    number: '+1664',
  },
  {
    name: 'Morocco',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/2/2c/Flag_of_Morocco.svg',
    number: '+212',
  },
  {
    name: 'Mozambique',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/d/d0/Flag_of_Mozambique.svg',
    number: '+258',
  },
  {
    name: 'Myanmar',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/8/8c/Flag_of_Myanmar.svg',
    number: '+95',
  },
  {
    name: 'Namibia',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/0/00/Flag_of_Namibia.svg',
    number: '+264',
  },
  {
    name: 'Nauru',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/3/30/Flag_of_Nauru.svg',
    number: '+674',
  },
  {
    name: 'Nepal',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/9/9b/Flag_of_Nepal.svg',
    number: '+977',
  },
  {
    name: 'Netherlands',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/2/20/Flag_of_the_Netherlands.svg',
    number: '+31',
  },
  {
    name: 'New Caledonia',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/2/23/Flag_of_New_Caledonia.svg',
    number: '+687',
  },
  {
    name: 'New Zealand',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/3/3e/Flag_of_New_Zealand.svg',
    number: '+64',
  },
  {
    name: 'Nicaragua',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Nicaragua.svg',
    number: '+505',
  },
  {
    name: 'Niger',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/f/f4/Flag_of_Niger.svg',
    number: '+227',
  },
  {
    name: 'Nigeria',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/7/79/Flag_of_Nigeria.svg',
    number: '+234',
  },
  {
    name: 'Niue',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_Niue.svg',
    number: '+683',
  },
  {
    name: 'Norfolk Island',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Norfolk_Island.svg',
    number: '+672',
  },
  {
    name: 'Northern Mariana Islands',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/e/e0/Flag_of_the_Northern_Mariana_Islands.svg',
    number: '+1 670',
  },
  {
    name: 'Norway',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Norway.svg',
    number: '+47',
  },
  {
    name: 'Oman',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/d/dd/Flag_of_Oman.svg',
    number: '+968',
  },
  {
    name: 'Pakistan',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/3/32/Flag_of_Pakistan.svg',
    number: '+92',
  },
  {
    name: 'Palau',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Palau.svg',
    number: '+680',
  },
  {
    name: 'Panama',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/a/ab/Flag_of_Panama.svg',
    number: '+507',
  },
  {
    name: 'Papua New Guinea',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/e/e3/Flag_of_Papua_New_Guinea.svg',
    number: '+675',
  },
  {
    name: 'Paraguay',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/2/27/Flag_of_Paraguay.svg',
    number: '+595',
  },
  {
    name: 'Peru',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/c/cf/Flag_of_Peru.svg',
    number: '+51',
  },
  {
    name: 'Philippines',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/9/99/Flag_of_the_Philippines.svg',
    number: '+63',
  },
  {
    name: 'Pitcairn',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/8/88/Flag_of_the_Pitcairn_Islands.svg',
    number: '+870',
  },
  {
    name: 'Poland',
    flag: 'https://upload.wikimedia.org/wikipedia/en/1/12/Flag_of_Poland.svg',
    number: '+48',
  },
  {
    name: 'Portugal',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_Portugal.svg',
    number: '+351',
  },
  {
    name: 'Puerto Rico',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/2/28/Flag_of_Puerto_Rico.svg',
    number: '+1 939',
  },
  {
    name: 'Qatar',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/6/65/Flag_of_Qatar.svg',
    number: '+974',
  },
  {
    name: 'Réunion',
    flag: 'https://upload.wikimedia.org/wikipedia/en/c/c3/Flag_of_France.svg',
    number: '+262',
  },
  {
    name: 'Romania',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/7/73/Flag_of_Romania.svg',
    number: '+40',
  },
  {
    name: 'Rwanda',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/1/17/Flag_of_Rwanda.svg',
    number: '+250',
  },
  {
    name: 'Saint Kitts and Nevis',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Saint_Kitts_and_Nevis.svg',
    number: '+1 869',
  },
  {
    name: 'Saint Lucia',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_Saint_Lucia.svg',
    number: '+1 758',
  },
  {
    name: 'Saint Pierre and Miquelon',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/7/74/Flag_of_Saint-Pierre_and_Miquelon.svg',
    number: '+508',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/6/6d/Flag_of_Saint_Vincent_and_the_Grenadines.svg',
    number: '+1 784',
  },
  {
    name: 'Samoa',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/3/31/Flag_of_Samoa.svg',
    number: '+685',
  },
  {
    name: 'San Marino',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/b/b1/Flag_of_San_Marino.svg',
    number: '+378',
  },
  {
    name: 'Sao Tome and Principe',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/4/4f/Flag_of_Sao_Tome_and_Principe.svg',
    number: '+239',
  },
  {
    name: 'Saudi Arabia',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/0/0d/Flag_of_Saudi_Arabia.svg',
    number: '+966',
  },
  {
    name: 'Senegal',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/f/fd/Flag_of_Senegal.svg',
    number: '+221',
  },
  {
    name: 'Serbia',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/f/ff/Flag_of_Serbia.svg',
    number: '+381',
  },
  {
    name: 'Seychelles',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/f/fc/Flag_of_Seychelles.svg',
    number: '+248',
  },
  {
    name: 'Sierra Leone',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/1/17/Flag_of_Sierra_Leone.svg',
    number: '+232',
  },
  {
    name: 'Singapore',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Singapore.svg',
    number: '+65',
  },
  {
    name: 'Slovakia',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/e/e6/Flag_of_Slovakia.svg',
    number: '+421',
  },
  {
    name: 'Slovenia',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/f/f0/Flag_of_Slovenia.svg',
    number: '+386',
  },
  {
    name: 'Solomon Islands',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/7/74/Flag_of_the_Solomon_Islands.svg',
    number: '+677',
  },
  {
    name: 'Somalia',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/a/a0/Flag_of_Somalia.svg',
    number: '+252',
  },
  {
    name: 'South Africa',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/a/af/Flag_of_South_Africa.svg',
    number: '+27',
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/e/ed/Flag_of_South_Georgia_and_the_South_Sandwich_Islands.svg',
    number: '+500',
  },
  {
    name: 'Spain',
    flag: 'https://upload.wikimedia.org/wikipedia/en/9/9a/Flag_of_Spain.svg',
    number: '+34',
  },
  {
    name: 'Sri Lanka',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Sri_Lanka.svg',
    number: '+94',
  },
  {
    name: 'Sudan',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_Sudan.svg',
    number: '+249',
  },
  {
    name: 'Suriname',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/6/60/Flag_of_Suriname.svg',
    number: '+597',
  },
  {
    name: 'Swaziland',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/1/1e/Flag_of_Swaziland.svg',
    number: '+268',
  },
  {
    name: 'Sweden',
    flag: 'https://upload.wikimedia.org/wikipedia/en/4/4c/Flag_of_Sweden.svg',
    number: '+46',
  },
  {
    name: 'Switzerland',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/0/08/Flag_of_Switzerland_%28Pantone%29.svg',
    number: '+41',
  },
  {
    name: 'Syrian Arab Republic',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/5/53/Flag_of_Syria.svg',
    number: '+963',
  },
  {
    name: 'Taiwan',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/7/72/Flag_of_the_Republic_of_China.svg',
    number: '+886',
  },
  {
    name: 'Tajikistan',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/d/d0/Flag_of_Tajikistan.svg',
    number: '+992',
  },
  {
    name: 'Thailand',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/a/a9/Flag_of_Thailand.svg',
    number: '+66',
  },
  {
    name: 'Timor-Leste',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/2/26/Flag_of_East_Timor.svg',
    number: '+670',
  },
  {
    name: 'Togo',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/6/68/Flag_of_Togo.svg',
    number: '+228',
  },
  {
    name: 'Tokelau',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/8/8e/Flag_of_Tokelau.svg',
    number: '+690',
  },
  {
    name: 'Tonga',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Tonga.svg',
    number: '+676',
  },
  {
    name: 'Trinidad and Tobago',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/6/64/Flag_of_Trinidad_and_Tobago.svg',
    number: '+1 868',
  },
  {
    name: 'Tunisia',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/c/ce/Flag_of_Tunisia.svg',
    number: '+216',
  },
  {
    name: 'Turkey',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/b/b4/Flag_of_Turkey.svg',
    number: '+90',
  },
  {
    name: 'Turkmenistan',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/1/1b/Flag_of_Turkmenistan.svg',
    number: '+993',
  },
  {
    name: 'Turks and Caicos Islands',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/a/a0/Flag_of_the_Turks_and_Caicos_Islands.svg',
    number: '+1 649',
  },
  {
    name: 'Tuvalu',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/3/38/Flag_of_Tuvalu.svg',
    number: '+688',
  },
  {
    name: 'Uganda',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/4/4e/Flag_of_Uganda.svg',
    number: '+256',
  },
  {
    name: 'Ukraine',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/4/49/Flag_of_Ukraine.svg',
    number: '+380',
  },
  {
    name: 'United Arab Emirates',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/c/cb/Flag_of_the_United_Arab_Emirates.svg',
    number: '+971',
  },
  {
    name: 'United Kingdom',
    flag:
      'https://upload.wikimedia.org/wikipedia/en/a/ae/Flag_of_the_United_Kingdom.svg',
    number: '+44',
  },
  {
    name: 'United States',
    flag:
      'https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg',
    number: '+1',
  },
  {
    name: 'United States Minor Outlying Islands',
    flag:
      'https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg',
    number: '+1581',
  },
  {
    name: 'Uruguay',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Uruguay.svg',
    number: '+598',
  },
  {
    name: 'Uzbekistan',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/8/84/Flag_of_Uzbekistan.svg',
    number: '+998',
  },
  {
    name: 'Vanuatu',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Vanuatu.svg',
    number: '+678',
  },
  {
    name: 'Viet Nam',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/2/21/Flag_of_Vietnam.svg',
    number: '+84',
  },
  {
    name: 'Wallis and Futuna',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/d/d2/Flag_of_Wallis_and_Futuna.svg',
    number: '+681',
  },
  {
    name: 'Yemen',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/8/89/Flag_of_Yemen.svg',
    number: '+967',
  },
  {
    name: 'Zambia',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/0/06/Flag_of_Zambia.svg',
    number: '+260',
  },
  {
    name: 'Zimbabwe',
    flag:
      'https://upload.wikimedia.org/wikipedia/commons/6/6a/Flag_of_Zimbabwe.svg',
    number: '+263',
  },
];

export default COUNTRY_CODE;
