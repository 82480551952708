import { connect } from 'react-redux';

import { Creators } from 'services/redux/buyVignette/actions';
import OneDayTarifChoice from './components';

const mapStateToProps = state => {
  return {
    isGettingObjectTypes: state.buyVignet.isGettingObjectTypes,
    isGettingDriveTypes: state.buyVignet.isGettingDriveTypes,
    data: state.buyVignet.tarifChoices,
    error: state.buyVignet.createDayError,
    objectTypes: state.buyVignet.objectTypes,
    driveTypes: state.buyVignet.driveTypes,
    isCreatingDay: state.buyVignet.isCreatingDay,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getTarifChoices: data => {
      dispatch(Creators.buyVignetGetTarifChoicesRequest(data));
    },
    getObjectTypes: () => {
      dispatch(Creators.buyVignetGetObjectTypesRequest());
    },
    getDriveTypes: () => {
      dispatch(Creators.buyVignetGetDriveTypesRequest());
    },
    createDayRequest: data => {
      dispatch(Creators.buyVignetCreateDayRequest(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OneDayTarifChoice);
