import { apiRequest } from '../apiRequest';
import {
  GET_OWNER_DETAILS,
  UPDATE_OWNER_DETAILS,
  UPDATE_CREDENTIALS,
  GET_PAYMENT_INSTALLMENT,
  PAYMENT_BANK_LISTS,
  PAYMENT_MANDATE,
  PAYMENT_DIRECT,
  UPDATE_OBJECT_LOCATION,
  PAYMENT_HAS_MANDATE,
  CREATE_INSTALLMENTS,
} from '../urls';

export const extendGetOwnerDetails = data => {
  return apiRequest('POST', GET_OWNER_DETAILS, data);
};

export const extendUpdateOwnerDetails = data => {
  return apiRequest('POST', UPDATE_OWNER_DETAILS, data);
};

export const extendUpdateCredentials = data => {
  return apiRequest('POST', UPDATE_CREDENTIALS, data);
};

export const extendGetPaymentInstallment = data => {
  return apiRequest('GET', GET_PAYMENT_INSTALLMENT, data);
};

export const extendGetPaymentBankLists = data => {
  return apiRequest('POST', PAYMENT_BANK_LISTS, data);
};

export const extendPaymentMandate = data => {
  return apiRequest('POST', PAYMENT_MANDATE, data);
};

export const extendPaymentDirect = data => {
  return apiRequest('POST', PAYMENT_DIRECT, data);
};

export const extendUpdateObjectLocation = data => {
  return apiRequest('POST', UPDATE_OBJECT_LOCATION, data);
};

export const extendPaymentHasMandate = () => {
  return apiRequest('POST', PAYMENT_HAS_MANDATE);
};

export const extendCreateInstallments = data => {
  return apiRequest('POST', CREATE_INSTALLMENTS, data);
};
