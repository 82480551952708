import { connect } from 'react-redux';

import { Creators as StepCreators } from '../../../../services/redux/extendVignetteSteps/actions';
import VignetInfo from './components';

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    updateRoute: route => {
      dispatch(StepCreators.updateBuyVignetteRoute(route));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VignetInfo);
