import { connect } from 'react-redux';

import { Creators } from 'services/redux/buyVignette/actions';
import AnnualPaymentInstruction from './components';

const mapStateToProps = state => {
  return {
    isCheckingMandate: state.buyVignet.isCheckingHasMandate,
    error: state.buyVignet.hasMandateError,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    checkMandate: () => {
      dispatch(Creators.buyVignetHasMandateRequest());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AnnualPaymentInstruction);
