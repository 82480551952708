import './index.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form, Input, Icon, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import StepsLayout from '../../StepsLayout';
import StepsController from '../../StepsController';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Creators } from 'services/redux/vignette/actions';
import { StepCard } from 'components/StepCard';

import ForgotpinModal from './ForgotpinModal';

class StepOne extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
      // new pin request
      wasSuccess: false,
      // vignet_number for new pin
      number: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isCheckingPin &&
      !this.props.isCheckingPin &&
      !this.props.isCheckPinCodeError
    ) {
      setTimeout(this.props.nextStep, 0);
    }

    if (
      prevProps.isResetingPin &&
      !this.props.isResetingPin &&
      !this.props.isResetPinCodeError
    ) {
      setTimeout(() => {
        this.setState({
          wasSuccess: true,
        });
      }, 0);
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.nextStep();
  };

  prevStep = () => {
    this.props.prevStep();
  };

  nextStep = () => {
    if (this.props.isCheckingPin) {
      return;
    }

    this.props.form.validateFields((errors, values) => {
      if (!errors) {
        this.props.checkPinCode(values);
      }
    });
  };

  openModal = () => {
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    if (this.props.isResetingPin) {
      return;
    }

    this.setState({ isModalOpen: false, wasSuccess: false });
  };

  onChangePin = ({ vignet_number }) => {
    this.setState({ number: vignet_number.value });
  };

  requestNewPin = e => {
    e.preventDefault();
    if (this.state.number) {
      this.props.resetPinCode(this.state.number);
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { intl } = this.props;
    return (
      <StepCard
        id="activate.steps.header"
        steps={this.props.steps}
        current={this.props.current}
        onPrevious={this.prevStep}
        onNext={this.nextStep}
        className={'step-one'}
        contactDetails="extend.step.verification.contact"
        xs={23}
        sm={22}
        md={14}
        lg={10}
        xl={16}
        xxl={13}
      >
        <Col align="center">
          <h2>
            <FormattedMessage id="activate.steps.one.header" />
          </h2>
          <p className="text-desc">
            <FormattedMessage id="activate.steps.one.subheader" />
          </p>
        </Col>

        <Col xs={24} xl={13}>
          <p className="description">
            <FormattedMessage
              id="activate.steps.one.description"
              values={{ bold: text => <strong> {text} </strong> }}
            />
          </p>
          <Col align="center">
            <img
              className="check-pin-icon"
              src={require('assets/Vignet NR indication-ax.svg')}
              alt="activate vignette number"
            />
          </Col>
          <p>
            <FormattedMessage id="vignette.activation.lostpin" />
            <a onClick={this.openModal}>
              <FormattedMessage id="vignette.activation.requestpin" />
            </a>
          </p>
        </Col>
        <Col xs={24} xl={11}>
          <Form onSubmit={this.handleSubmit} className="activation-form">
            <Form.Item label={intl.formatMessage({ id: 'vignette.number' })}>
              {getFieldDecorator('vignet_number', {
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'activate.errors.enter.number',
                    }),
                  },
                ],
              })(<Input type="text" />)}
            </Form.Item>
            <Form.Item
              label={intl.formatMessage({ id: 'vignette.activationpin' })}
            >
              {getFieldDecorator('pin', {
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'activate.errors.enter.pin',
                    }),
                  },
                ],
              })(<Input type="password" />)}
            </Form.Item>
            {this.props.isCheckPinCodeError && (
              <Form.Item
                help={this.props.checkPinCodeErrorMessage}
                validateStatus="error"
              ></Form.Item>
            )}
          </Form>
        </Col>

        <ForgotpinModal
          visible={this.state.isModalOpen}
          onCancel={this.closeModal}
          onSubmit={this.requestNewPin}
          wasSuccess={this.state.wasSuccess}
          onChange={this.onChangePin}
          isLoading={this.props.isResetingPin}
          isResetPinCodeError={this.props.isResetPinCodeError}
          resetPinCodeErrorMessage={this.props.resetPinCodeErrorMessage}
        />
      </StepCard>
    );
  }
}

const mapPropsToFields = props => {
  // vignette number from the Redirect url afte QR code scan
  const qrVignette = _.get(window || {}, 'history.state.state.vignette', '');

  return {
    vignet_number: Form.createFormField({
      value: props.vignet_number || qrVignette,
    }),
    pin: Form.createFormField({
      value: props.pin,
    }),
  };
};

const WrappedStepOne = Form.create({
  name: 'vignette_activation',
  mapPropsToFields,
})(StepOne);

WrappedStepOne.propTypes = {
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const {
    isCheckingPin,
    isCheckPinCodeError,
    checkPinCodeErrorMessage,
    checkPinCodeResult,
    vignet_number,
    pin,

    isResetingPin,
    isResetPinCodeError,
    resetPinCodeErrorMessage,
    resetPinCodeResult,
  } = state.vignette;

  return {
    isCheckingPin,
    isCheckPinCodeError,
    checkPinCodeErrorMessage,
    checkPinCodeResult,
    vignet_number,
    pin,

    isResetingPin,
    isResetPinCodeError,
    resetPinCodeErrorMessage,
    resetPinCodeResult,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    checkPinCode: data => {
      dispatch(Creators.checkPinCodeRequest(data.vignet_number, data.pin));
    },
    resetPinCode: vignet_number => {
      dispatch(Creators.resetPinCodeRequest(vignet_number));
    },
  };
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(WrappedStepOne),
);
