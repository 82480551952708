import { all, fork } from 'redux-saga/effects';

import { watchLogin } from './auth';
import {
  watchCheckPingCode,
  watchResetPingCode,
  watchGetVesselTypes,
  watchGetVesselDetails,
  watchUpdateVessel,
  watchUploadVesselPictures,
} from './vignette';
import {
  watchExtendVerifyVignet,
  watchExtendGetVignetCost,
} from './extendVerification';
import { watchExtendCancelVignette } from './extendCancelVignette';
import {
  watchExtendGetUserInfo,
  watchExtendUpdateUserInfo,
  watchExtendUpdateCredentials,
  watchExtendGetPaymentInstallment,
  watchGetPaymentBankLists,
  watchGetPaymentMandate,
  watchGetPaymentDirect,
  watchUpdateObjectLocation,
  watchPaymentHasMandate,
  watchCreateInstallments,
} from './extendVignette';
import {
  watchGetTarifChoices,
  watchBuyVignetLogin,
  watchBuyVignetCreateAccount,
  watchBuyVignetGetOwner,
  watchBuyVignetUpdateOwner,
  watchBuyVignetGetDriveTypes,
  watchBuyVignetGetObjectTypes,
  watchBuyVignetCreateDayRequest,
  watchBuyVignetBankLists,
  watchBuyVignetMakeDayPayment,
  watchBuyVignetGetOverview,
  watchBuyVignetUploadDocuments,
  watchBuyVignetHasMandate,
  watchBuyVignetCreateMandate,
  watchBuyVignetGetInstallments,
  watchBuyVignetGetVignetCost,
  watchBuyVignetCreateDirect,
  watchBuyVignetCreateInstallment,
  watchBuyVignetCheckDay,
  watchBuyVignetConfirmation,
  watchBuyVignetForgotPassword,
  watchBuyVignetResetPassword,
} from './buyVignette';

export default function* rootSaga() {
  yield all([
    watchLogin(),
    watchExtendVerifyVignet(),
    watchExtendGetVignetCost(),
    watchCheckPingCode(),
    watchResetPingCode(),
    watchGetVesselTypes(),
    watchGetVesselDetails(),
    watchUpdateVessel(),
    watchUploadVesselPictures(),
    watchExtendCancelVignette(),
    watchExtendGetUserInfo(),
    watchExtendUpdateUserInfo(),
    watchExtendUpdateCredentials(),
    watchExtendGetPaymentInstallment(),
    watchGetPaymentBankLists(),
    watchGetPaymentMandate(),
    watchGetPaymentDirect(),
    watchUpdateObjectLocation(),
    watchPaymentHasMandate(),
    watchCreateInstallments(),
    watchGetTarifChoices(),
    watchBuyVignetLogin(),
    watchBuyVignetCreateAccount(),
    watchBuyVignetGetOwner(),
    watchBuyVignetUpdateOwner(),
    watchBuyVignetGetDriveTypes(),
    watchBuyVignetGetObjectTypes(),
    watchBuyVignetCreateDayRequest(),
    watchBuyVignetBankLists(),
    watchBuyVignetMakeDayPayment(),
    watchBuyVignetGetOverview(),
    watchBuyVignetUploadDocuments(),
    watchBuyVignetHasMandate(),
    watchBuyVignetCreateMandate(),
    watchBuyVignetGetInstallments(),
    watchBuyVignetGetVignetCost(),
    watchBuyVignetCreateDirect(),
    watchBuyVignetCreateInstallment(),
    watchBuyVignetCheckDay(),
    watchBuyVignetConfirmation(),
    watchBuyVignetForgotPassword(),
    watchBuyVignetResetPassword(),
  ]);
}
