import { createReducer } from 'reduxsauce';
import Types from './actionTypes';

export const INITIAL_STATE = { route: null, buy_vignete_route: null };

export const updateExtendVignetteRoute = (state = INITIAL_STATE, action) => {
  return { ...state, route: action.route };
};

export const updatBuyVignetteRoute = (state = INITIAL_STATE, action) => {
  return { ...state, buy_vignete_route: action.route };
};

export const HANDLERS = {
  [Types.UPDATE_EXTEND_VIGNETTE_ROUTE]: updateExtendVignetteRoute,
  [Types.UPDATE_BUY_VIGNETTE_ROUTE]: updatBuyVignetteRoute,
};

export default createReducer(INITIAL_STATE, HANDLERS);
