import React from 'react';
import Success from 'components/Success';
import { logOut } from 'util/helpers/normalizer';

const ExtendVignetteCancellationCompleted = props => {
  logOut();
  return (
    <Success
      id="extend.cancellation.message"
      titleId="extend.cancel.signout.title"
    />
  );
};

export default ExtendVignetteCancellationCompleted;
