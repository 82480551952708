import React from 'react';
import { Row, Col, Input, Form, Alert } from 'antd';
import { FormattedMessage } from 'react-intl';

import './styles.scss';
import { EXTEND_VIGNETTE_ROUTE } from 'constants/index';
import StepCard from 'components/StepCard';

let timer;

class Account extends React.Component {
  state = {
    visible: true,
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ visible: true });
        if (this.props.onSubmit) {
          this.props.onSubmit(values);
        } else {
          this.props.updateCredentials(values.email, values.password);
        }
      }
    });
  };

  validPassword = (rule, value, callback) => {
    const { form } = this.props;
    const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;
    if (regex.test(value)) {
      callback();
    } else {
      callback(<FormattedMessage id="password.strength.error" />);
    }
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback(<FormattedMessage id="passWordNotMatch" />);
    } else {
      callback();
    }
  };

  compareToFirstEmail = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('email')) {
      callback(<FormattedMessage id="emailNotMatch" />);
    } else {
      callback();
    }
  };

  renderServerErrorMsg = () => {
    const { error } = this.props;
    if (error) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        this.setState({ visible: false });
      }, 5000);
      if (this.state.visible) {
        return (
          <div className="error-msg">
            <Alert description={error} type="error" />
          </div>
        );
      }
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <StepCard
          current={this.props.current || 4}
          steps={this.props.steps || 7}
          onPrevious={() =>
            this.props.onPrevious
              ? this.props.onPrevious()
              : this.props.updateRoute(EXTEND_VIGNETTE_ROUTE.PERSONAL_INFO_STEP)
          }
          id={this.props.id || 'extend.step.personal.title'}
          onNext={this.handleSubmit}
          className="account-creation-extend-step"
          isNextLoading={this.props.isLoading}
        >
          <Row>
            <div>
              <p className="title">
                <FormattedMessage id="extend.step.account" />
              </p>
            </div>
            <Col span={24} xs={24} lg={10} md={24} sm={24}>
              <p>
                <FormattedMessage
                  id={
                    this.props.instruction || 'extend.step.account.information'
                  }
                />
              </p>
            </Col>
            <Col span={24} xs={24} lg={4} md={12} sm={24}></Col>
            <Col span={24} xs={24} lg={10} md={24} sm={24}>
              <Row>
                <Col span={24} xs={24} lg={24} md={24} sm={24}>
                  <div className="input-wrapper">
                    <label>
                      <FormattedMessage id="email" />*
                    </label>
                    <Form.Item>
                      {getFieldDecorator('email', {
                        rules: [
                          {
                            required: true,
                            message: <FormattedMessage id="required.error" />,
                          },
                          {
                            type: 'email',
                            message: (
                              <FormattedMessage id="email.regex.error" />
                            ),
                          },
                        ],
                      })(<Input />)}
                    </Form.Item>
                  </div>
                </Col>
                <Col span={24} xs={24} lg={24} md={24} sm={24}>
                  <div className="input-wrapper">
                    <label>
                      <FormattedMessage id="confirmEmail" />*
                    </label>
                    <Form.Item>
                      {getFieldDecorator('confirm_email', {
                        rules: [
                          {
                            required: true,
                            message: <FormattedMessage id="required.error" />,
                          },
                          {
                            type: 'email',
                            message: (
                              <FormattedMessage id="email.regex.error" />
                            ),
                          },
                          {
                            validator: this.compareToFirstEmail,
                          },
                        ],
                      })(<Input />)}
                    </Form.Item>
                  </div>
                </Col>
                <Col span={24} xs={24} lg={24} md={24} sm={24}>
                  <div className="input-wrapper">
                    <label>
                      <FormattedMessage id="password" />*
                    </label>
                    <Form.Item>
                      {getFieldDecorator('password', {
                        rules: [
                          {
                            required: true,
                            message: <FormattedMessage id="required.error" />,
                          },
                          {
                            validator: this.validPassword,
                          },
                        ],
                      })(<Input type="password" />)}
                    </Form.Item>
                  </div>
                </Col>
                <Col span={24} xs={24} lg={24} md={24} sm={24}>
                  <div className="input-wrapper">
                    <label>
                      <FormattedMessage id="confirmPassword" />*
                    </label>
                    <Form.Item>
                      {getFieldDecorator('confirm_passwrd', {
                        rules: [
                          {
                            required: true,
                            message: <FormattedMessage id="required.error" />,
                          },
                          {
                            validator: this.compareToFirstPassword,
                          },
                        ],
                      })(<Input type="password" />)}
                    </Form.Item>
                  </div>
                  {this.renderServerErrorMsg()}
                </Col>
              </Row>
            </Col>
          </Row>
        </StepCard>
      </Form>
    );
  }
}

const AccountForm = Form.create({ name: 'AccountForm' })(Account);
export default AccountForm;
