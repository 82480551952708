import React from 'react';
import { history } from 'util/helpers/history';
import Overview from 'containers/BuyVignetteSteps/Steps/Overview/components';
import { FormattedMessage } from 'react-intl';

const getErrorMsg = pathname => {
  if (pathname.substring(31) === 'display') {
    return false;
  } else if (pathname.substring(31) === 'cancel') {
    return 'extend.step.payment.direct.cancel.message';
  } else if (pathname.substring(31) === 'error') {
    return 'extend.step.payment.direct.error.message';
  } else if (pathname.substring(31) === 'reject') {
    return 'extend.step.payment.direct.reject.message';
  }
};

const OneDayOverview = props => {
  const errorMsg = getErrorMsg(history.location.pathname);
  return (
    <Overview
      onPrevious={() => history.goBack()}
      onNext={() => history.push('/buy/vignette/one-day/payment')}
      current={2}
      steps={4}
      {...(errorMsg
        ? {
            bankError: <FormattedMessage id={errorMsg} />,
          }
        : {})}
      title="buy.vignet.instruction.one.day.title"
      customer={JSON.parse(sessionStorage.getItem('customer_info'))}
      vignetInfo={JSON.parse(sessionStorage.getItem('vignet_info'))}
      oneDay={true}
    />
  );
};

export default OneDayOverview;
