import { createTypes } from 'reduxsauce';

export default createTypes(
  `
  LOGIN_REQUEST
  LOGIN_SUCCESS
  LOGIN_FAILURE
 
  LOGOUT
`,
  {},
);
