import { createTypes } from 'reduxsauce';

export default createTypes(
  `
  EXTEND_GET_CUSTOMER_REQUEST
  EXTEND_GET_CUSTOMER_SUCCESS
  EXTEND_GET_CUSTOMER_FAILURE

  EXTEND_UPDATE_CUSTOMER_REQUEST
  EXTEND_UPDATE_CUSTOMER_SUCCESS
  EXTEND_UPDATE_CUSTOMER_FAILURE

  EXTEND_UPDATE_CREDENTIALS_REQUEST
  EXTEND_UPDATE_CREDENTIALS_SUCCESS
  EXTEND_UPDATE_CREDENTIALS_FAILURE

  EXTEND_GET_PAYMENT_INSTALLMENT_REQUEST
  EXTEND_GET_PAYMENT_INSTALLMENT_SUCCESS
  EXTEND_GET_PAYMENT_INSTALLMENT_FAILURE

  EXTEND_GET_PAYMENT_BANK_LISTS_REQUEST
  EXTEND_GET_PAYMENT_BANK_LISTS_SUCCESS
  EXTEND_GET_PAYMENT_BANK_LISTS_FAILURE

  EXTEND_GET_PAYMENT_MANDATE_REQUEST
  EXTEND_GET_PAYMENT_MANDATE_SUCCESS
  EXTEND_GET_PAYMENT_MANDATE_FAILURE

  EXTEND_GET_PAYMENT_DIRECT_REQUEST
  EXTEND_GET_PAYMENT_DIRECT_SUCCESS
  EXTEND_GET_PAYMENT_DIRECT_FAILURE

  EXTEND_UPDATE_OBJECT_LOCATION_REQUEST
  EXTEND_UPDATE_OBJECT_LOCATION_SUCCESS
  EXTEND_UPDATE_OBJECT_LOCATION_FAILURE

  EXTEND_PAYMENT_HAS_MANDATE_REQUEST
  EXTEND_PAYMENT_HAS_MANDATE_SUCCESS
  EXTEND_PAYMENT_HAS_MANDATE_FAILURE

  EXTEND_CREATE_INSTALLMENTS_REQUEST
  EXTEND_CREATE_INSTALLMENTS_SUCCESS
  EXTEND_CREATE_INSTALLMENTS_FAILURE

`,
  {},
);
