import React from 'react';
import { Button } from 'antd';

import './index.scss';
import { history } from 'util/helpers/history';
import { logOut } from 'util/helpers/normalizer';

const authRoutes = [
  '/buy/vignette/annual/personal',
  '/buy/vignette/annual/upload-files',
  '/buy/vignette/annual/overview',
  '/buy/vignette/annual/payment-instruction/details',
  '/buy/vignette/annual/payment-instruction/cancel',
  '/buy/vignette/annual/payment-instruction/error',
  '/buy/vignette/annual/payment-instruction/reject',
  '/buy/vignette/annual/mandate',
  '/buy/vignette/annual/payment-choice/display',
  '/buy/vignette/annual/payment-choice/cancel',
  '/buy/vignette/annual/payment-choice/error',
  '/buy/vignette/annual/payment-choice/reject',
  '/buy/vignette/annual/direct/payment',
  '/buy/vignette/annual/success',
  '/buy/vignette/payment/direct/',
  '/buy/vignette/payment/direct-cancel/',
  '/buy/vignette/payment/direct-error/',
  '/buy/vignette/payment/direct-reject/',
];

const Header = ({ goToHome, location }) => {
  return (
    <div className="app header">
      <img
        src={require('assets/app_logo.png')}
        alt="App logo"
        onClick={goToHome}
      />
      {authRoutes.includes(location.pathname) ? (
        <Button
          onClick={() => {
            logOut();
            history.push('/');
          }}
          style={{
            margin: '20px',
            float: 'right',
          }}
        >
          Logout
        </Button>
      ) : null}
    </div>
  );
};

export default Header;
