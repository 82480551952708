import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Col, Card, Steps, Icon } from 'antd';
import { FormattedMessage } from 'react-intl';
import Instruction from 'components/Instruction';

const instructions = [
  'activate.instructions.one',
  'activate.instructions.two',
  // 'activate.instructions.three',
  'activate.instructions.four',
  'activate.instructions.five',
];

class ActivateVignette extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Instruction
        className={'activation-steps'}
        header="activate.header"
        subheader="activate.subheader"
        iconURL={require('assets/Clock 5-ax.svg')}
        messages={instructions}
        description={{
          title: 'activate.instructions.desc.title',
          bullets: [
            'activate.instructions.bullets.one',
            'activate.instructions.bullets.two',
            'activate.instructions.bullets.three',
          ],
        }}
        onStart={this.props.goToActivateVignetteSteps}
      />
    );
  }
}

ActivateVignette.propTypes = {
  isloading: PropTypes.bool,
  goToActivateVignetteSteps: PropTypes.func.isRequired,
};

// you can use formik or redux forms

export default ActivateVignette;
