import { call, put, takeLeading } from 'redux-saga/effects';

import { Creators, Types } from '../../redux/extendCancelVignette/actions';
import { extendCancelVignette as extendCancelVignetteApi } from '../../api/extendCancelVignette';

export function* cancelVignette(actions) {
  try {
    const { email } = actions;
    const response = yield call(extendCancelVignetteApi, { email });
    yield put(Creators.cancelVignetSuccess(response.data));
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.cancelVignetFailure(error_message));
  }
}

export function* watchExtendCancelVignette() {
  yield takeLeading(Types.CANCEL_VIGNET_REQUEST, cancelVignette);
}
