import { connect } from 'react-redux';

import { Creators as StepsCreators } from '../../../../services/redux/extendVignetteSteps/actions';
import { Creators } from '../../../../services/redux/extendVignette/actions';
import MandateBankLists from './components';

const mapStateToProps = state => {
  return {
    banks: state.extendVignetData.banks,
    isFetchingBanks: state.extendVignetData.isFetchingBanks,
    isFetchingMandate: state.extendVignetData.isFetchingMandate,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateRoute: route => {
      dispatch(StepsCreators.updateExtendVignetteRoute(route));
    },
    getBanks: () => {
      dispatch(Creators.extendGetPaymentBankListsRequest('mandate'));
    },
    postMandate: issuer => {
      dispatch(Creators.extendGetPaymentMandateRequest(issuer));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MandateBankLists);
