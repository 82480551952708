import './index.scss';
import React from 'react';
import StepsLayout from '../../StepsLayout';
import { FormattedMessage } from 'react-intl';
import { StepCard } from 'components/StepCard';
import { Row, Button } from 'antd';
import { Creators } from 'services/redux/vignette/actions';
import { connect } from 'react-redux';

class StepFive extends React.Component {
  prevStep = () => {
    this.props.prevStep();
  };

  nextStep = () => {
    this.props.nextStep();
  };

  componentDidMount() {
    this.props.onCompleteClear();
  }

  render() {
    return (
      <StepCard
        id="activate.steps.header"
        steps={this.props.steps}
        current={this.props.current}
        xs={23}
        sm={22}
        md={14}
        lg={10}
        xl={16}
        xxl={13}
        className="step-five"
      >
        <Row className="heading-container">
          <h1>
            <img
              src={require('assets/activate-success-icon.png')}
              alt="success icon"
            />
          </h1>
          <br />
          <p className="forgot-pin-form success-message">
            <FormattedMessage id="activate.steps.five.heading" />
          </p>
        </Row>

        <Row type="flex" justify="center">
          <p className="step-description">
            <FormattedMessage id="activate.steps.five.message" />
          </p>
          <p className="step-description">
            <FormattedMessage id="activate.steps.five.submessage" />
          </p>
        </Row>

        <Row type="flex" justify="center">
          <Button
            className="step-complete"
            type="primary"
            onClick={this.nextStep}
          >
            <FormattedMessage id="COMPLETE" />
          </Button>
        </Row>
      </StepCard>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onCompleteClear: () => {
      return dispatch(Creators.onCompleteClear());
    },
  };
};

export default connect(null, mapDispatchToProps)(StepFive);
