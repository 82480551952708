import React from 'react';
import { history } from 'util/helpers/history';

export default class BuyHandleRedirect extends React.Component {
  componentDidMount() {
    let paymentType = history.location.pathname.split('/')[4];
    if (paymentType === 'mandate') {
      history.replace('/buy/vignette/steps', { route: 'mandate' });
    } else if (paymentType === 'direct') {
      history.replace('/buy/vignette/steps', { route: 'direct' });
    } else if (paymentType === 'mandate-cancel') {
      history.replace('/buy/vignette/steps', {
        route: 'mandate-failed',
        id: 'extend.step.payment.mandate.cancel.message',
      });
    } else if (paymentType === 'mandate-error') {
      history.replace('/buy/vignette/steps', {
        route: 'mandate-failed',
        id: 'extend.step.payment.mandate.error.message',
      });
    } else if (paymentType === 'mandate-reject') {
      history.replace('/buy/vignette/steps', {
        route: 'mandate-failed',
        id: 'extend.step.payment.mandate.reject.message',
      });
    } else if (paymentType === 'direct-cancel') {
      history.replace('/buy/vignette/steps', {
        route: 'direct-failed',
        id: 'extend.step.payment.direct.cancel.message',
      });
    } else if (paymentType === 'direct-error') {
      history.replace('/buy/vignette/steps', {
        route: 'direct-failed',
        id: 'extend.step.payment.direct.error.message',
      });
    } else if (paymentType === 'direct-reject') {
      history.replace('/buy/vignette/steps', {
        route: 'direct-failed',
        id: 'extend.step.payment.direct.reject.message',
      });
    }

    // ONE DAY
    else if (paymentType === 'one-day') {
      history.replace('/buy/vignette/steps', { route: 'one-day' });
    } else if (paymentType === 'one-day-cancel') {
      history.replace('/buy/vignette/steps', {
        route: 'one-day-failed',
        id: 'extend.step.payment.mandate.cancel.message',
      });
    } else if (paymentType === 'one-day-error') {
      history.replace('/buy/vignette/steps', {
        route: 'one-day-failed',
        id: 'extend.step.payment.mandate.error.message',
      });
    } else if (paymentType === 'one-day-reject') {
      history.replace('/buy/vignette/steps', {
        route: 'one-day-failed',
        id: 'extend.step.payment.mandate.reject.message',
      });
    }
  }

  render() {
    return null;
  }
}
