import React from 'react';
import { Row, Col, Input, Checkbox, Form, Alert, Modal } from 'antd';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import './styles.scss';
import { EXTEND_VIGNETTE_ROUTE } from 'constants/index';
import StepCard from 'components/StepCard';

let timer;

class ExtendStep4 extends React.Component {
  state = {
    showModal: false,
    visible: true,
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ visible: true });
        this.props.cancelVignette(values.email);
      }
    });
  };

  handleCancel = () => {
    this.setState({ showModal: false });
  };

  checkCheckBox = (rule, value, callback) => {
    if (!value) {
      callback(<FormattedMessage id="help.confirm.this" />);
    } else {
      callback();
    }
  };

  compareToFirstEmail = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('email')) {
      callback(<FormattedMessage id="emailNotMatch" />);
    } else {
      callback();
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.isCancelled) {
      this.props.updateRoute(EXTEND_VIGNETTE_ROUTE.CANCELLED_ROUTE);
    }
  }

  renderServerErrorMsg = () => {
    const { error } = this.props;
    if (error) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        this.setState({ visible: false });
      }, 5000);
      if (this.state.visible) {
        return (
          <div className="error-msg">
            <Alert description={error} type="error" />
          </div>
        );
      }
    }
  };

  renderModal = () => {
    return (
      <Modal
        visible={this.state.showModal}
        onCancel={this.handleCancel}
        footer={null}
        width={720}
      >
        <div className="modal-wrapper">
          <p className="modal-title">
            <FormattedMessage id="extend.step.payment.terms.condition" />
          </p>
          <p className="modal-instruction">
            <FormattedHTMLMessage id={this.state.message} />
          </p>
        </div>
      </Modal>
    );
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <StepCard
          onPrevious={() =>
            this.props.updateRoute(EXTEND_VIGNETTE_ROUTE.CHOICE_ROUTE)
          }
          id="extend.cancel.signout.title"
          className="extend-sign-out-vignette"
          onNext={this.handleSubmit}
          isNextLoading={this.props.isLoading}
        >
          <Row>
            {this.renderModal()}
            <div>
              <p className="title">
                <FormattedMessage id="extend.signout" />
              </p>
            </div>
            <div>
              <p>
                <FormattedMessage id="extend.signout.message" />
              </p>
              <p>
                <FormattedMessage id="extend.signout.consequences" />
              </p>
            </div>
            <Col span={24} xs={24} lg={8} md={11} sm={24}>
              <label>
                <FormattedMessage id="email" />*
              </label>
              <Form.Item>
                {getFieldDecorator('email', {
                  rules: [
                    {
                      required: true,
                      message: <FormattedMessage id="required.error" />,
                    },
                    {
                      type: 'email',
                      message: <FormattedMessage id="email.regex.error" />,
                    },
                  ],
                })(<Input />)}
              </Form.Item>

              <label>
                <FormattedMessage id="confirmEmail" />*
              </label>
              <Form.Item>
                {getFieldDecorator('confirm_email', {
                  rules: [
                    {
                      required: true,
                      message: <FormattedMessage id="required.error" />,
                    },
                    {
                      type: 'email',
                      message: <FormattedMessage id="email.regex.error" />,
                    },
                    {
                      validator: this.compareToFirstEmail,
                    },
                  ],
                })(<Input />)}
              </Form.Item>
            </Col>
            <Col span={24} xs={24} lg={24} md={24} sm={24}>
              <div className="checkbox-wrapper">
                <Form.Item>
                  {getFieldDecorator('checkbox', {
                    valuePropName: 'checked',
                    rules: [{ validator: this.checkCheckBox }],
                  })(
                    <Checkbox>
                      <span>
                        <FormattedMessage id="extend.step.payment.info.agreement" />
                      </span>
                    </Checkbox>,
                  )}
                  {/* <a
                    onClick={() =>
                      this.setState({
                        showModal: true,
                        message: 'extend.step.terms.and.condition.message',
                      })
                    }
                  >
                    <FormattedMessage id="extend.step.terms.and.condition" />
                  </a> */}
                  <a
                    href="https://decentrale.regelgeving.overheid.nl/cvdr/xhtmloutput/Actueel/Amsterdam/CVDR635093.html"
                    target="_blank"
                  >
                    <FormattedMessage id="extend.step.port.rules" />
                    &nbsp;
                  </a>
                  en{' '}
                  <a
                    href="https://decentrale.regelgeving.overheid.nl/cvdr/xhtmloutput/Historie/Amsterdam/73996/CVDR73996_9.html"
                    target="_blank"
                  >
                    VOB
                  </a>
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator('checkbox2', {
                    valuePropName: 'checked',
                    rules: [{ validator: this.checkCheckBox }],
                  })(
                    <Checkbox>
                      <FormattedMessage id="extend.signout.agree" />
                      &nbsp;
                    </Checkbox>,
                  )}
                </Form.Item>
              </div>
              {this.renderServerErrorMsg()}
            </Col>
          </Row>
        </StepCard>
      </Form>
    );
  }
}

const SignoutForm = Form.create({ name: 'ExtendStep4' })(ExtendStep4);

export default SignoutForm;
